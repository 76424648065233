import React, { useState } from "react";

// Redux
import { useDispatch } from "react-redux";
import { addPayment } from "../../../redux/features/guest/guestSlice";

import "./styles.css";

function AddPaymentModal({ toggleModal, bookID, guestID }) {
  const dispatch = useDispatch();
  const [amount, setAmount] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(addPayment({ guestID, bookID, amount }));

    toggleModal(bookID);
  };

  return (
    <div className="modal">
      <div className="addPaymentModal">
        <img
          src={require("../../../assets/icons/cross-icon.png").default}
          alt=""
          className="addPaymentModal__cancelBtn"
          onClick={toggleModal}
        />
        <h3 className="addPaymentModal__title">Add Payment</h3>
        <form onSubmit={onSubmit}>
          <label htmlFor="amount">Payment Amount</label>
          <input
            id="amount"
            type="number"
            className="addPaymentModal__amount"
            placeholder={`Enter payment amount here`}
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          ></input>
          <button className="addPaymentModal__sendBtn">Add</button>
        </form>
      </div>
    </div>
  );
}

export default AddPaymentModal;

import React, { useEffect, useState } from "react";
import Select from "react-select";

import "./RentList.css";

import Loading from "../../../../helpers/loading";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  getRent,
  changeYear,
  setRent,
} from "../../../../redux/features/rent/rentSlice";

const options = [
  { value: "2077", label: "2077" },
  { value: "2078", label: "2078" },
  { value: "2079", label: "2079" },
  { value: "2080", label: "2080" },
  { value: "2081", label: "2081" },
  { value: "2082", label: "2082" },
];

function Rent() {
  const dispatch = useDispatch();
  const { year, rootMonths, loading } = useSelector((state) => state.rent);

  const [months, setMonths] = useState({});

  useEffect(() => {
    setMonths(rootMonths);
  }, [rootMonths]);

  useEffect(() => {
    dispatch(getRent({ year }));
  }, [dispatch, year]);

  const handleChange = (data, e) => {
    setMonths((prevMonths) => ({
      ...prevMonths,
      [`${data}`]: {
        ...prevMonths[`${data}`],
        [e.target.name]: e.target.value,
      },
    }));
  };

  const handleCheckboxChange = (data, e) => {
    setMonths((prevMonths) => ({
      ...prevMonths,
      [`${data}`]: {
        ...prevMonths[`${data}`],
        [e.target.name]: e.target.checked,
      },
    }));
  };

  // Submit Form
  const addRent = (e) => {
    e.preventDefault();
    dispatch(setRent({ year, months }));
  };

  return (
    <div className="rent">
      <div className="rent__box">
        <div className="rent__title">
          <h4>Rent</h4>
        </div>
        <div className="rent__body">
          <div className="rent__year">
            <label htmlFor="year">Year</label>
            <Select
              options={options}
              defaultValue={{ value: year, label: year }}
              onChange={(option) => {
                dispatch(changeYear(option.value));
              }}
            />
          </div>
          {loading ? (
            <Loading />
          ) : (
            <form className="rent__months" onSubmit={addRent}>
              {Object.keys(rootMonths).map((month, index) => (
                <div className="rent__itemGroup" key={index}>
                  <input
                    type="text"
                    name="month"
                    className="rent__monthLabel"
                    disabled
                    defaultValue={month}
                  />
                  <div className="rent__paid">
                    <input
                      type="checkbox"
                      name="paid"
                      defaultValue={rootMonths[month]?.paid}
                      defaultChecked={rootMonths[month]?.paid}
                      onChange={(e) => handleCheckboxChange(month, e)}
                    />
                    <span>Paid</span>
                  </div>
                  <input
                    type="text"
                    name="remarks"
                    placeholder="Add Remarks"
                    defaultValue={rootMonths[month]?.remarks}
                    onChange={(e) => handleChange(month, e)}
                  />
                </div>
              ))}
              <button className="rent__save">Save</button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}

export default Rent;

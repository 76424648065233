import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import axios from "../../../helpers/axios";

export const loginUser = createAsyncThunk(
  "app/loginUser",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const response = await axios.post("/login", {
        email,
        password,
      });
      const { data } = response;

      localStorage.setItem("token", data.token);

      // Page reload set since fetch ai not working after login api call success and only works after page reload
      window.location.reload();

      return data;
    } catch (err) {
      localStorage.removeItem("token");

      toast.error(err.response.data.error[0]);

      return rejectWithValue(err.response.data);
    }
  }
);

export const loadUser = createAsyncThunk(
  "app/loadUser",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/user");

      toast.success("Login Successful!");

      return response.data;
    } catch (err) {
      localStorage.removeItem("token");

      toast.error("Login Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

export const changePassword = createAsyncThunk(
  "app/changePassword",
  async ({ password, password_confirmation }, { rejectWithValue }) => {
    try {
      await axios.post("/passwordupdate", {
        password,
        password_confirmation,
      });

      toast.success("Password Changed!");
    } catch (err) {
      toast.error(err.response.data.errors.password[0]);

      return rejectWithValue(err.response.data);
    }
  }
);

export const appSlice = createSlice({
  name: "app",
  initialState: {
    token: localStorage.getItem("token"),
    auth: false,
    user: null,
    loading: false,
  },
  reducers: {
    logout: (state) => {
      localStorage.removeItem("token");
      state.token = null;
      state.auth = false;
      state.user = null;
      state.loading = false;
    },
  },
  extraReducers: {
    [loginUser.pending]: (state) => {
      state.loading = true;
    },
    [loginUser.fulfilled]: (state, { payload }) => {
      state.token = payload.token;
      state.auth = true;
      state.loading = false;
    },
    [loginUser.rejected]: (state) => {
      state.loading = false;
      state.token = null;
      state.auth = false;
      state.user = null;
    },
    [loadUser.pending]: (state) => {
      state.loading = true;
    },
    [loadUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.auth = true;
      state.user = payload;
    },
    [loadUser.rejected]: (state) => {
      state.loading = false;
      state.token = null;
      state.auth = false;
      state.user = null;
    },
    [changePassword.pending]: (state) => {
      state.loading = true;
    },
    [changePassword.fulfilled]: (state) => {
      state.loading = false;
      state.token = null;
      state.auth = false;
      state.user = null;
    },
    [changePassword.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const { logout } = appSlice.actions;

export const selectAuth = (state) => state.app.auth;

export default appSlice.reducer;

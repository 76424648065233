import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  getGuestBooking,
  updateGuestBooking,
} from "../../../redux/features/guest/guestSlice";
import { getAvailableRooms } from "../../../redux/features/room/roomSlice";

import Loading from "../../../helpers/loading";
import GuestForm from "../GuestForm";

import "./styles.css";

function GuestEdit() {
  const history = useHistory();
  const { rid, guestID } = useParams();
  const dispatch = useDispatch();
  const { guestBooking, loading } = useSelector((state) => state.guest);
  const { availableRooms } = useSelector((state) => state.room);

  useEffect(() => {
    dispatch(getAvailableRooms());
    dispatch(getGuestBooking(rid));
  }, [dispatch, rid]);

  const update = (data) => {
    dispatch(updateGuestBooking({ rid, data, guestID }));

    history.goBack();
  };

  return (
    <div className="guestEdit">
      <div className="guestEdit__box">
        <h4 className="guestEdit__title">Edit Guest Booking</h4>
        {loading ? (
          <Loading />
        ) : (
          <GuestForm
            buttonTitle="Update"
            defaultValues={guestBooking}
            submit={update}
            availableRooms={availableRooms}
          />
        )}
      </div>
    </div>
  );
}

export default GuestEdit;

import React, { useState, useMemo, useEffect } from "react";

import { NepaliDatePicker } from "nepali-datepicker-reactjs";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { getDeletedSales } from "../../../../redux/features/salesAndExpenses/salesSlice";

import Filter from "../../../../components/filter/Filter";
import TableDeleted from "../../../../components/table/TableDeleted";

import Loading from "../../../../helpers/loading";

import { SALES_COLUMNS } from "../SalesColumn";
import { todayBS } from "../../../../helpers/nepaliDate";

function DeletedSales() {
  const dispatch = useDispatch();
  const { records, loading } = useSelector((state) => state.sales);

  const [fromDate, setFromDate] = useState(todayBS);
  const [toDate, setToDate] = useState(todayBS);
  const [q, setQ] = useState("");
  const [searchColumns, setSearchColumns] = useState(["name"]);

  const columnsHeader = useMemo(() => SALES_COLUMNS, []);
  const data = useMemo(() => records, [records]);

  useEffect(() => {
    dispatch(
      getDeletedSales({
        from: fromDate,
        to: toDate,
      })
    );
  }, [dispatch, fromDate, toDate]);

  let columnsTitle = data[0] && Object.keys(data[0]);

  // Search Fields
  const filterColumns = [{ name: "Sales Name", value: "name" }];

  function search(rows) {
    return rows.filter((row) =>
      searchColumns.some(
        (column) =>
          row[column]?.toString().toLowerCase().indexOf(q.toLowerCase()) > -1
      )
    );
  }

  return (
    <div className="sales__box">
      <div className="sales__heading">
        <div className="sales__date">
          <div>
            <label>From : </label>
            <NepaliDatePicker
              className="g-datePicker"
              value={fromDate}
              options={{ calenderLocale: "en", valueLocale: "en" }}
              onChange={(value) => setFromDate(value)}
            />
          </div>
          <div>
            <label>To : </label>
            <NepaliDatePicker
              className="g-datePicker"
              value={toDate}
              options={{ calenderLocale: "en", valueLocale: "en" }}
              onChange={(value) => setToDate(value)}
            />
          </div>
        </div>
      </div>

      <Filter
        q={q}
        setQ={setQ}
        filterColumns={filterColumns}
        searchColumns={searchColumns}
        setSearchColumns={setSearchColumns}
      />

      <div className="sales__details">
        {loading ? (
          <Loading />
        ) : (
          <TableDeleted
            columns={columnsHeader}
            data={search(data)}
            columnsTitle={columnsTitle}
            link=""
            onEdit={null}
            onDelete={null}
          />
        )}
      </div>
    </div>
  );
}

export default DeletedSales;

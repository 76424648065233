import React, { useState } from "react";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { changePassword } from "../../../redux/features/app/appSlice";

import Loading from "../../../helpers/loading";

import "./Profile.css";

function Profile() {
  const { user, loading } = useSelector((state) => state.app);
  const dispatch = useDispatch();

  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  const [showChangePassword, setShowChangePassword] = useState(false);

  const passwordChange = (e) => {
    e.preventDefault();

    dispatch(
      changePassword({
        password: password,
        password_confirmation: passwordConfirmation,
      })
    );
  };

  return (
    <div className="profile">
      <div className="profile__box">
        <>
          <div className="profile__content">
            <h4 className="profile__title">User Profile</h4>
            <div className="profile__group">
              <h5>ID</h5>
              <span>{user.id}</span>
            </div>
            <div className="profile__group">
              <h5>Name</h5>
              <span>{user.name}</span>
            </div>
            <div className="profile__group">
              <h5>Email</h5>
              <span>{user.email}</span>
            </div>

            {showChangePassword ? (
              <>
                {loading ? (
                  <Loading />
                ) : (
                  <form onSubmit={passwordChange} className="profile__form">
                    <div className="profile__group">
                      <h5>Password</h5>
                      <input
                        type="password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>{" "}
                    <div className="profile__group">
                      <h5>Email</h5>
                      <input
                        type="password"
                        name="passwordConfirmation"
                        value={passwordConfirmation}
                        onChange={(e) =>
                          setPasswordConfirmation(e.target.value)
                        }
                      />
                    </div>
                    <button className="profile__updatePasswordBtn">
                      Update Password
                    </button>
                    <button
                      className="profile__backBtn"
                      onClick={() => setShowChangePassword(false)}
                    >
                      Hide
                    </button>
                  </form>
                )}
              </>
            ) : (
              <button
                className="profile__changePasswordBtn"
                onClick={() => setShowChangePassword((prev) => !prev)}
              >
                Change Password
              </button>
            )}
          </div>
        </>
      </div>
    </div>
  );
}

export default Profile;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  getSalary,
  changeSalaryYear,
  setSalary,
} from "../../../../redux/features/utilities/staffSalarySlice";

import "./styles.css";
import Loading from "../../../../helpers/loading";

const options = [
  { value: "2077", label: "2077" },
  { value: "2078", label: "2078" },
  { value: "2079", label: "2079" },
  { value: "2080", label: "2080" },
  { value: "2081", label: "2081" },
  { value: "2082", label: "2082" },
];

function Salary() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { year, rootMonths, loading } = useSelector(
    (state) => state.staffSalary
  );

  const [months, setMonths] = useState({});

  useEffect(() => {
    setMonths(rootMonths);
  }, [rootMonths]);

  useEffect(() => {
    dispatch(getSalary({ id, year }));
  }, [dispatch, id, year]);

  const handleChange = (data, e) => {
    setMonths((prevMonths) => ({
      ...prevMonths,
      [`${data}`]: {
        ...prevMonths[`${data}`],
        [e.target.name]: e.target.value,
      },
    }));
  };

  // Submit Form
  const addSalary = (e) => {
    e.preventDefault();
    dispatch(setSalary({ year, months, id }));
  };

  return (
    <div className="salary">
      <div className="salary__body">
        <div className="salary__year">
          <label htmlFor="year">Year</label>
          <Select
            options={options}
            defaultValue={{ value: year, label: year }}
            onChange={(option) => {
              dispatch(changeSalaryYear(option.value));
            }}
          />
        </div>
        {loading ? (
          <Loading />
        ) : (
          <>
            <div className="salary__labelGroup">
              <label>Month</label>
              <label>Paid Amount</label>
              <label>Unpaid Amount</label>
            </div>
            <form className="salary__months" onSubmit={addSalary}>
              {Object.keys(rootMonths).map((month, index) => (
                <div className="salary__itemGroup" key={index}>
                  <input
                    type="text"
                    name="month"
                    className="salary__monthLabel"
                    disabled
                    defaultValue={month}
                  />

                  <input
                    type="number"
                    name="paid_amount"
                    placeholder="Paid amount"
                    key={`paid_amount:${rootMonths[month]?.paid_amount}`}
                    defaultValue={rootMonths[month]?.paid_amount}
                    onChange={(e) => handleChange(month, e)}
                  />
                  <input
                    type="number"
                    name="unpaid_amount"
                    placeholder="Unpaid amount"
                    key={`unpaid_amount:${rootMonths[month]?.unpaid_amount}`}
                    defaultValue={rootMonths[month]?.unpaid_amount}
                    onChange={(e) => handleChange(month, e)}
                  />
                </div>
              ))}
              <button className="salary__save">Save</button>
            </form>
          </>
        )}
      </div>
    </div>
  );
}

export default Salary;

import React, { useState } from "react";

// Redux
import { useDispatch } from "react-redux";
import { sendBill } from "../../../redux/features/guest/guestSlice";

import SMSModal from "./SMSModal";

function SMSBillModal({ toggleModal, id }) {
  const [content, setContent] = useState("");
  const dispatch = useDispatch();

  const onSend = (e) => {
    e.preventDefault();
    // console.log("Bill Sent");

    dispatch(sendBill({ id, content }));
  };
  return (
    <SMSModal
      toggleModal={toggleModal}
      title="Bill"
      send={onSend}
      setMsg={setContent}
    />
  );
}

export default SMSBillModal;

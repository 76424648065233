import React from "react";
import { useHistory } from "react-router";

// Redux
import { useDispatch } from "react-redux";
import { addReservation } from "../../../redux/features/reservation/reservationSlice";

import "./styles.css";
import ReservationForm from "../ReservationForm";

import { availableRooms } from "../../../helpers/rooms";

function ReservationAdd() {
  const history = useHistory();
  const dispatch = useDispatch();

  const add = (data) => {
    dispatch(addReservation(data));

    history.push("/reservation/list");
  };

  return (
    <div className="reservation">
      <div className="reservation__box">
        <h4 className="reservation__title">Reservation</h4>

        <ReservationForm
          submit={add}
          buttonTitle="Add"
          availableRooms={availableRooms}
        />
      </div>
    </div>
  );
}

export default ReservationAdd;

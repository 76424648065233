import React from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";

// nepali calendar
import { NepaliDatePicker } from "nepali-datepicker-reactjs";

function ReservationForm({
  submit,
  buttonTitle,
  defaultValues,
  availableRooms,
}) {
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues });

  return (
    <>
      <div>
        <span className="form__error">
          {errors.name?.type === "required" && "Name is required"}
        </span>
        <span className="form__error">
          {errors.phone?.type === "required" && "Phone is required"}
        </span>
        <span className="form__error">
          {errors.address?.type === "required" && "Address is required"}
        </span>
        <span className="form__error">
          {errors.adult_num?.type === "required" && "Adult Guest is required"}
        </span>
        <span className="form__error">
          {errors.rooms?.type === "required" && "At least one room is required"}
        </span>
        <span className="form__error">
          {errors.check_in?.type === "required" && "Check In Date is required"}
        </span>
        <span className="form__error">
          {errors.stayDays?.type === "required" && "Stay Days is required"}
        </span>
        <span className="form__error">
          {errors.charge?.type === "required" && "Charge Amount is required"}
        </span>
        <br />
      </div>
      <form className="reservation__form" onSubmit={handleSubmit(submit)}>
        <div className="reservation__formDiv">
          <div className="reservation__formLeft">
            <div className="reservation__formField">
              <label htmlFor="name">
                Name <span>*</span>
              </label>
              <input
                type="text"
                id="name"
                placeholder="Guest Name"
                {...register("name", { required: true })}
              />
            </div>
            <div className="reservation__formField">
              <label htmlFor="number">
                Phone <span>*</span>
              </label>
              <input
                type="number"
                id="number"
                placeholder="Guest Phone Number"
                {...register("phone_number", { required: true })}
              />
            </div>
            <div className="reservation__formField">
              <label htmlFor="address">
                Address <span>*</span>
              </label>
              <input
                type="text"
                id="address"
                placeholder="Guest Address"
                {...register("address", { required: true })}
              />
            </div>
            <div className="reservation__formField add_guest">
              <label htmlFor="adult_num">
                Guests <span>*</span>
              </label>
              <span>Adults</span>
              <input
                id="adult_num"
                type="number"
                defaultValue="1"
                className="reservation__Guests"
                {...register("adult_num")}
              />
              <span>Children</span>
              <input
                id="child_num"
                type="number"
                defaultValue="0"
                className="reservation__Guests"
                {...register("child_num")}
              />
            </div>
            <div className="reservation__formField">
              <label htmlFor="rooms">
                Rooms <span>*</span>
              </label>
              <Controller
                id="rooms"
                name="rooms"
                rules={{ required: true }}
                control={control}
                // defaultValue={[availableRooms[0]]}
                render={({ field }) => (
                  <Select
                    {...field}
                    isMulti
                    options={availableRooms}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                )}
              />
            </div>
            <div className="reservation__formField reservation__bookedFrom">
              <label htmlFor="booked_from">Booked From</label>
              <input
                type="text"
                id="booked_from"
                placeholder="From where booking is done"
                {...register("booked_from")}
              />
            </div>
          </div>
          <div className="reservation__formRight">
            <div className="reservation__formField">
              <label htmlFor="check_in">
                Check In <span>*</span>
              </label>
              <Controller
                name="check_in"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange } }) => (
                  <NepaliDatePicker
                    inputClassName="reservation__calendar"
                    options={{ calenderLocale: "en", valueLocale: "en" }}
                    onChange={(value) => onChange(value)}
                  />
                )}
              />
            </div>
            <div className="reservation__formField">
              <label htmlFor="stayDays">
                Stay Days <span>*</span>
              </label>
              <input
                type="number"
                id="stayDays"
                placeholder="Expected stay days"
                {...register("expected_stay_days", { required: true })}
              />
            </div>

            <div className="reservation__formField">
              <label htmlFor="charge">
                Charge <span>*</span>
              </label>
              <input
                id="charge"
                type="number"
                min="0"
                defaultValue="0"
                placeholder="Charge Amount"
                {...register("charge", { required: true })}
              />
            </div>
            <div className="reservation__formField">
              <label htmlFor="remarks">Remarks</label>
              <textarea
                type="text"
                id="remarks"
                rows="5"
                {...register("remarks")}
              />
            </div>
          </div>
        </div>

        <button className="reservation__btn">{buttonTitle}</button>
      </form>
    </>
  );
}

export default ReservationForm;

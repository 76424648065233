import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import axios from "../../../helpers/axios";

export const getHotelPolicies = createAsyncThunk(
  "hotelPolicies/getHotelPolicies",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get("/pages/get/hotel-policies");

      return data.results;
    } catch (err) {
      toast.error("Hotel Policies Load Failed");

      return rejectWithValue(err.response.data);
    }
  }
);

export const updateHotelPolicies = createAsyncThunk(
  "hotelPolicies/updateHotelPolicies",
  async (description, { rejectWithValue, dispatch }) => {
    try {
      await axios.post("/pages/update/hotel-policies", {
        description,
      });

      dispatch(getHotelPolicies());

      toast.success("Hotel Policies Updated!");
    } catch (err) {
      toast.error("Hotel Policies Update Failed");

      return rejectWithValue(err.response.data);
    }
  }
);

export const hotelPoliciesSlice = createSlice({
  name: "hotelPolicies",
  initialState: {
    title: "",
    description: "",
    loading: false,
  },
  extraReducers: {
    [getHotelPolicies.pending]: (state) => {
      state.loading = true;
    },
    [getHotelPolicies.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.title = payload.title;
      state.description = payload.description;
    },
    [getHotelPolicies.rejected]: (state) => {
      state.loading = false;
    },
    [updateHotelPolicies.pending]: (state) => {
      state.loading = true;
    },
    [updateHotelPolicies.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default hotelPoliciesSlice.reducer;

import React, { useEffect } from "react";

// Redux
import { useDispatch, useSelector } from "react-redux";

import Notifying from "../../../components/notifying/Notifying";

import ElectricityLogoSvg from "../../../assets/images/electricity-logo.svg";

import "./Electricity.css";
import {
  getElectricity,
  updateElectricity,
} from "../../../redux/features/utilities/electricitySlice";

function Electricity() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getElectricity());
  }, [dispatch]);

  const { notifyingDate, startDate, useDays, loading } = useSelector(
    (state) => state.electricity
  );

  return (
    <div className="electricity">
      <Notifying
        logo={ElectricityLogoSvg}
        notifyingDate={notifyingDate}
        startDate={startDate}
        useDays={useDays}
        update={updateElectricity}
        loading={loading}
      />
    </div>
  );
}

export default Electricity;

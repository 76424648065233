import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import ReactLoading from "react-loading";

// nepali calendar
import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import "nepali-datepicker-reactjs/dist/index.css";

import "./Notifying.css";

// Redux
import { useDispatch } from "react-redux";

function Notifying({
  logo,
  notifyingDate,
  startDate,
  useDays,
  update,
  loading,
}) {
  const dispatch = useDispatch();
  const { register, control, handleSubmit, reset } = useForm();

  useEffect(() => {
    reset({
      startDate: startDate,
      useDays: useDays,
    });
  }, [reset, startDate, useDays]);

  const onSubmit = (data) => {
    dispatch(update(data));
  };

  return (
    <div className="notifying">
      <img src={logo} alt="notfiying logo" />
      {loading ? (
        <ReactLoading
          type="spinningBubbles"
          color="#C19433"
          height={50}
          width={50}
        />
      ) : (
        <>
          <span>Next Notiying Date: </span>
          <h3>{notifyingDate}</h3>
          <form className="notifying__form" onSubmit={handleSubmit(onSubmit)}>
            <div className="notifying__formField">
              <label htmlFor="start">Start Date</label>
              <Controller
                name="startDate"
                control={control}
                render={({ field: { onChange } }) => (
                  <NepaliDatePicker
                    // className="notifying__calendar"
                    value={startDate}
                    inputClassName="notifying__calendar"
                    options={{ calenderLocale: "en", valueLocale: "en" }}
                    onChange={(value) => onChange(value)}
                  />
                )}
              />
            </div>
            <div className="notifying__formField">
              <label htmlFor="useDays">Use Days</label>
              <input type="number" {...register("useDays")} />
            </div>
            <button>Update</button>
          </form>
        </>
      )}
    </div>
  );
}

export default Notifying;

import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import "./Functions.css";

import HomeSvg from "../../assets/icons/home.svg";
import CCTVSvg from "../../assets/icons/cctv.svg";
import WaterSvg from "../../assets/icons/water.svg";
import BookingPulseSvg from "../../assets/icons/booking-pulse.svg";
import ReservationSvg from "../../assets/icons/reservation.svg";
import SalesSvg from "../../assets/icons/sales.svg";
import GuestListSvg from "../../assets/icons/guest.svg";
import UtilitiesSvg from "../../assets/icons/utility.svg";
import useWindowDimensions from "../../helpers/useWindowDimensions";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { getWater } from "../../redux/features/utilities/waterSlice";

export const features = [
  {
    id: "1",
    name: "Home",
    icon: HomeSvg,
    link: "/",
  },
  {
    id: "2",
    name: "CCTV",
    icon: CCTVSvg,
    link: "https://www.google.com",
  },
  {
    id: "3",
    name: "Water",
    icon: WaterSvg,
    link: "/services/water",
  },
  {
    id: "4",
    name: "Booking Pulse",
    icon: BookingPulseSvg,
    link: "https://partner.booking.com/en-gb/solutions/pulse-app",
  },
  {
    id: "5",
    name: "Counter Reservation",
    icon: ReservationSvg,
    link: "/reservation/list",
  },
  {
    id: "6",
    name: "Daily Sales & Expenses",
    icon: SalesSvg,
    link: "/sae/daily",
  },
  {
    id: "7",
    name: "Guest List",
    icon: GuestListSvg,
    link: "/guest/list",
  },
  {
    id: "8",
    name: "Utilities",
    icon: UtilitiesSvg,
    link: "/utilities",
  },
];

const openTab = (link) => {
  // open new link
  window.open(link, "_blank", "noopener,noreferrer");
};

function Functions() {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const { remainingDays } = useSelector((state) => state.water);
  const [sidebar, setSidebar] = useState(false);

  useEffect(() => {
    dispatch(getWater());
  }, [dispatch, remainingDays]);

  const toggleSidebar = () => {
    setSidebar((prevSidebar) => !prevSidebar);
  };

  return width > 768 ? (
    <div className="functions">
      {features.map(({ id, name, icon, link }) => (
        <div key={id}>
          {/* Link or url check for some routes */}
          {name === "CCTV" || name === "Booking Pulse" ? (
            <div className="functions__item" onClick={() => openTab(link)}>
              <img src={icon} alt={name + " icon"} />
              <span>{name}</span>
            </div>
          ) : (
            <NavLink to={link} className="functions__item">
              {name === "Water" && (
                <div className="functions__countDays">
                  <span>{remainingDays}</span>
                </div>
              )}
              <img src={icon} alt={name + " icon"} />
              <span>{name}</span>
            </NavLink>
          )}
        </div>
      ))}
    </div>
  ) : (
    // features design for smaller devices
    <div className="functions__sm">
      <img
        src={require("../../assets/icons/sidebar.png").default}
        alt="sidebar icon"
        className="functions__sidebarIcon"
        onClick={toggleSidebar}
      />
      {sidebar ? (
        <div className="functions__sidebarActions">
          {features.map(({ id, name, icon, link }) => (
            <div key={id}>
              {/* Link or url check for some routes */}
              {name === "CCTV" || name === "Booking Pulse" ? (
                <div className="functions__item" onClick={() => openTab(link)}>
                  <img src={icon} alt={name + " icon"} />
                </div>
              ) : (
                <NavLink
                  to={link}
                  className="functions__item"
                  onClick={() => setSidebar(false)}
                >
                  {name === "Water" && (
                    <div className="functions__countDays">
                      <span>6</span>
                    </div>
                  )}
                  <img src={icon} alt={name + " icon"} />
                </NavLink>
              )}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
}

export default Functions;

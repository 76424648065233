import React, { useState, useEffect } from "react";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  getSMSInfo,
  sendInfo,
  updateSMSInfo,
} from "../../../redux/features/guest/guestSlice";

import SMSModal from "./SMSModal";

import Loading from "../../../helpers/loading";

function SMSInfoModal({ toggleModal, id }) {
  const [content, setContent] = useState("");
  const dispatch = useDispatch();
  const { smsInfo, loading } = useSelector((state) => state.guest);

  useEffect(() => {
    dispatch(getSMSInfo());
    setContent(smsInfo);
  }, [dispatch, smsInfo]);

  const onSend = (e) => {
    e.preventDefault();

    dispatch(sendInfo({ id, content }));
  };

  const onSaveInfo = () => {
    dispatch(updateSMSInfo({ content }));
  };

  return loading ? (
    <Loading />
  ) : (
    <SMSModal
      toggleModal={toggleModal}
      title="Info"
      send={onSend}
      content={content}
      setMsg={setContent}
      saveInfo={true}
      onSaveInfo={onSaveInfo}
    />
  );
}

export default SMSInfoModal;

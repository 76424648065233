export const COST_COLUMNS = [
  {
    Header: "Id",
    accessor: "uid",
  },
  {
    Header: "Expense",
    accessor: "expense",
  },
  {
    Header: "Amount",
    accessor: "amount",
  },
  {
    Header: "Date",
    accessor: "date",
  },
];

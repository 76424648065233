import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  getStaffDetails,
  updateStaff,
} from "../../../../../redux/features/utilities/staffSalarySlice";

import StaffForm from "../StaffForm";
import Loading from "../../../../../helpers/loading";

import "./styles.css";

function StaffEdit() {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { staff, loading } = useSelector((state) => state.staffSalary);

  useEffect(() => {
    dispatch(getStaffDetails(id));
  }, [dispatch, id]);

  const onUpdate = (data) => {
    dispatch(updateStaff({ data, id }));
    history.push("/utilities/staff");
  };

  return (
    <div className="staffEdit">
      <div className="container__box">
        <h4>Edit Staff </h4>
        {loading ? (
          <Loading />
        ) : (
          <StaffForm
            defaultValues={staff}
            submit={onUpdate}
            buttonTitle="Update"
          />
        )}
      </div>
    </div>
  );
}

export default StaffEdit;

import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  getCapitalInvestment,
  updateCapitalInvestment,
} from "../../../../redux/features/utilities/capitalInvestmentSlice";

import Loading from "../../../../helpers/loading";

import "../CapitalInvestment.css";
import CapitalInvestmentForm from "../CapitalInvestmentForm";

function CapitalInvestmentEdit() {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { investment, loading } = useSelector(
    (state) => state.capitalInvestment
  );

  useEffect(() => {
    dispatch(getCapitalInvestment(id));
  }, [dispatch, id]);

  const onAdd = (data) => {
    dispatch(updateCapitalInvestment({ data, id }));
    history.push("/utilities/investment");
  };

  return (
    <div className="capitalInvestmentEdit">
      <div className="container__box">
        <h4>Add Capital Investment</h4>
        {loading ? (
          <Loading />
        ) : (
          <CapitalInvestmentForm
            submit={onAdd}
            buttonTitle="Edit"
            defaultValues={investment}
          />
        )}
      </div>
    </div>
  );
}

export default CapitalInvestmentEdit;

import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { addStaff } from "../../../../../redux/features/utilities/staffSalarySlice";
import StaffForm from "../StaffForm";

import "./styles.css";

function StaffAdd() {
  const history = useHistory();
  const dispatch = useDispatch();

  const defaultValues = {
    name: "",
    designation: "",
    joined_date: "",
    salary: 0,
    phonenum: "",
    address: "",
  };

  const onAdd = (data) => {
    dispatch(addStaff(data));
    history.push("/utilities/staff");
  };

  return (
    <div className="staffAdd">
      <div className="container__box">
        <h4>Add Staff </h4>

        <StaffForm
          defaultValues={defaultValues}
          submit={onAdd}
          buttonTitle="Add"
        />
      </div>
    </div>
  );
}

export default StaffAdd;

import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  getReservation,
  updateReservation,
} from "../../../redux/features/reservation/reservationSlice";

import "./styles.css";
import ReservationForm from "../ReservationForm";

import Loading from "../../../helpers/loading";
import { availableRooms } from "../../../helpers/rooms";

function ReservationEdit() {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { reservation, loading } = useSelector((state) => state.reservation);

  useEffect(() => {
    dispatch(getReservation(id));
  }, [dispatch, id]);

  const update = (data) => {
    dispatch(updateReservation({ data, id }));

    history.push("/reservation/list");
  };

  return (
    <div className="reservation container">
      <div className="container__box">
        <h4>Reservation</h4>
        {loading ? (
          <Loading />
        ) : (
          <ReservationForm
            submit={update}
            buttonTitle="Update"
            defaultValues={reservation}
            availableRooms={availableRooms}
          />
        )}
      </div>
    </div>
  );
}

export default ReservationEdit;

import React, { useEffect } from "react";
import { Doughnut } from "react-chartjs-2";

import "./Daily.css";

import SalesDailyList from "../sales/dailyList";
import ExpenseDailyList from "../expenses/dailyList";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { getDailyReport } from "../../../../redux/features/salesAndExpenses/salesSlice";

import Loading from "../../../../helpers/loading";

function DailySAE() {
  const dispatch = useDispatch();
  const { dailyReport, loading, isProfit, profit, loss } = useSelector(
    (state) => state.sales
  );

  useEffect(() => {
    dispatch(getDailyReport());
  }, [dispatch]);

  return (
    <div className="daily">
      <div className="daily__box">
        <h4 className="daily__title">Daily Sales And Expenses</h4>
        <div className="daily__groups">
          <div className="daily__sales">
            <SalesDailyList />
          </div>
          {loading ? (
            <Loading />
          ) : (
            <div className="daily__chart">
              <div
                className={`daily__result ${
                  isProfit ? "daily__result--profit" : "daily__result--loss"
                }`}
              >
                <h5>
                  {isProfit ? "Profit" : "Loss"} : {isProfit ? profit : loss}
                </h5>
              </div>
              <Doughnut data={dailyReport} />
            </div>
          )}
          <div className="daily__expenses">
            <ExpenseDailyList />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DailySAE;

import React from "react";
import { NavLink } from "react-router-dom";

// Redux
import { useDispatch } from "react-redux";
import { logout } from "../../redux/features/app/appSlice";

import "./Header.css";

function Header() {
  const dispatch = useDispatch();
  return (
    <div className="header">
      <div className="header__profile" onClick={() => dispatch(logout())}>
        <img src={require("../../assets/icons/logout.png").default} alt="" />
      </div>
      <NavLink to="/" className="header__logo">
        <img src={require("../../assets/images/logo-lg.png").default} alt="" />
      </NavLink>
      <div>
        <NavLink to="/guest/add" className="header__userAdd">
          <img
            src={require("../../assets/icons/user-add.png").default}
            alt=""
          />
        </NavLink>
        <NavLink to="/user/profile" className="header__userProfile">
          <img
            src={require("../../assets/icons/user-profile.png").default}
            alt=""
          />
        </NavLink>
      </div>
    </div>
  );
}

export default Header;

import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  getAdvanceById,
  updateAdvance,
} from "../../../../../redux/features/utilities/inventorySlice";

import AdvanceForm from "../AdvanceForm";
import Loading from "../../../../../helpers/loading";

import "./styles.css";

function AdvanceEdit() {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { advance, loading } = useSelector((state) => state.inventory);

  useEffect(() => {
    dispatch(getAdvanceById(id));
  }, [dispatch, id]);

  const onUpdate = (data) => {
    dispatch(updateAdvance({ data, id }));
    history.push("/utilities/inventory");
  };

  return (
    <div className="advanceEdit">
      <div className="container__box">
        <h4>Edit Inventory Particular</h4>
        {loading ? (
          <Loading />
        ) : (
          <AdvanceForm
            submit={onUpdate}
            buttonTitle="Update"
            defaultValues={advance}
          />
        )}
      </div>
    </div>
  );
}

export default AdvanceEdit;

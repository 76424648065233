export const STAFF_SALARY_COLUMNS = [
  {
    Header: "Id",
    accessor: "id",
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Designation",
    accessor: "designation",
  },
  {
    Header: "Joined Date",
    accessor: "joined_date",
  },
  {
    Header: "Salary",
    accessor: "salary",
  },
  {
    Header: "Phone",
    accessor: "phonenum",
  },
  {
    Header: "Address",
    accessor: "address",
  },
];

import React from "react";

// Redux
import { useDispatch } from "react-redux";

import Rooms from "../../components/rooms/Rooms";
import { getAllRooms } from "../../redux/features/room/roomSlice";

import "./Home.css";

function Home() {
  const dispatch = useDispatch();

  dispatch(getAllRooms());

  return (
    <div className="home">
      <Rooms />
    </div>
  );
}

export default Home;

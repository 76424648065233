export const CREDIT_RECORD_COLUMNS = [
  {
    Header: "Id",
    accessor: "uid",
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Phone",
    accessor: "phone",
  },
  {
    Header: "Address",
    accessor: "address",
  },

  {
    Header: "Amount",
    accessor: "amount",
  },
  {
    Header: "Paid",
    accessor: "paid",
  },
];

import React from "react";
import { useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import "./Table.css";

function TableDeleted({ columns, data, columnsTitle, link, onEdit, onDelete }) {
  const history = useHistory();

  const rowDelete = (id) => {
    confirmAlert({
      title: "Confirm Delete",
      message: "Are you sure to delete?",
      buttons: [
        {
          label: "Yes",
          onClick: () => onDelete(id),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <table cellPadding={0} cellSpacing={0} className="tableDeleted">
      <thead>
        <tr className="tableDeleted__header">
          {columns.map((column, index) => (
            <th key={index}>{column.Header}</th>
          ))}
          {onEdit !== null && onDelete !== null && <th>Actions</th>}
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={`${item.uid || item.rid || item.id} ${index}`}>
            {columnsTitle.map((column, index) => {
              return column === "rooms" ? (
                <td
                  key={index}
                  onClick={() =>
                    link &&
                    history.push(`${link}/${item.uid || item.rid || item.id}`)
                  }
                >
                  {item.rooms.map((room, index) => (
                    <span className="table__room" key={index}>
                      {room.label}
                    </span>
                  ))}
                </td>
              ) : item[column] === true ? (
                <td key={index}>
                  <img
                    src={require("../../assets/icons/check-icon.png").default}
                    alt="check icon"
                    className="table__checkIcon"
                  />
                </td>
              ) : (
                <td
                  key={index}
                  onClick={() =>
                    link &&
                    history.push(`${link}/${item.uid || item.rid || item.id}`)
                  }
                >
                  {item[column]}
                </td>
              );
            })}
            {onEdit !== null && onDelete !== null && (
              <td className="table__actions">
                <img
                  src={require("../../assets/icons/edit-icon.png").default}
                  alt="edit icon"
                  className="actions__icon"
                  onClick={() => onEdit(item.uid || item.rid || item.id)}
                />
                <img
                  src={require("../../assets/icons/delete-icon.png").default}
                  alt="delete icon"
                  className="actions__icon"
                  onClick={() => rowDelete(item.uid || item.rid || item.id)}
                />
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default TableDeleted;

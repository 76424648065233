import { configureStore } from "@reduxjs/toolkit";
import appReducer from "../features/app/appSlice";

import waterReducer from "../features/utilities/waterSlice";
import tvReducer from "../features/utilities/tvSlice";
import telephoneReducer from "../features/utilities/telephoneSlice";
import electricityReducer from "../features/utilities/electricitySlice";

import capitalInvestmentReducer from "../features/utilities/capitalInvestmentSlice";
import creditRecordsReducer from "../features/utilities/creditRecordsSlice";

import rentReducer from "../features/rent/rentSlice";

import guestReducer from "../features/guest/guestSlice";
import reservationReducer from "../features/reservation/reservationSlice";

import salesReducer from "../features/salesAndExpenses/salesSlice";
import expensesReducer from "../features/salesAndExpenses/expensesSlice";
import roomReducer from "../features/room/roomSlice";
import inventoryReducer from "../features/utilities/inventorySlice";
import staffSalaryReducer from "../features/utilities/staffSalarySlice";
import hotelPoliciesReducer from "../features/utilities/hotelpoliciesSlice";

export default configureStore({
  reducer: {
    app: appReducer,
    guest: guestReducer,
    reservation: reservationReducer,
    water: waterReducer,
    tv: tvReducer,
    telephone: telephoneReducer,
    electricity: electricityReducer,
    rent: rentReducer,
    capitalInvestment: capitalInvestmentReducer,
    creditRecords: creditRecordsReducer,
    expenses: expensesReducer,
    sales: salesReducer,
    room: roomReducer,
    inventory: inventoryReducer,
    staffSalary: staffSalaryReducer,
    hotelPolicies: hotelPoliciesReducer,
  },
});

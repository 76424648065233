import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./styles.css";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  getHotelPolicies,
  updateHotelPolicies,
} from "../../../redux/features/utilities/hotelpoliciesSlice";

function PoliciesEdit() {
  const history = useHistory();
  const [text, setText] = useState("");
  const dispatch = useDispatch();
  const { description } = useSelector((state) => state.hotelPolicies);

  useEffect(() => {
    dispatch(getHotelPolicies());
    setText(description);
  }, [dispatch, description]);

  const handleChange = (value) => {
    setText(value);
  };

  const onSave = () => {
    dispatch(updateHotelPolicies(text));
    history.push("/policies");
  };

  return (
    <div className="policiesEdit">
      <Link to="/policies">Go Back to Hotel policies</Link>
      <div className="policiesEdit__editor">
        <ReactQuill
          value={text}
          onChange={handleChange}
          theme="snow"
          placeholder="Enter the policies"
          modules={{
            toolbar: {
              container: [
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                ["bold", "italic", "underline"],
                [{ list: "ordered" }, { list: "bullet" }],
                [{ align: [] }],
                ["link", "image"],
                ["clean"],
                [{ color: [] }],
              ],
            },
          }}
        />
      </div>
      <button onClick={onSave} className="policiesEdit__btn g-success__btn">
        Save
      </button>
    </div>
  );
}

export default PoliciesEdit;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import axios from "../../../helpers/axios";

export const getAllRooms = createAsyncThunk(
  "room/getAllRooms",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get("/rooms/all");

      return data;
    } catch (err) {
      toast.error("Unable to load rooms!");

      return rejectWithValue(err.response.data);
    }
  }
);

export const getAvailableRooms = createAsyncThunk(
  "room/getAvailableRooms",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get("/rooms/available");

      return data;
    } catch (err) {
      toast.error("Available rooms load failed");

      return rejectWithValue(err.response.data);
    }
  }
);

export const roomSlice = createSlice({
  name: "room",
  initialState: {
    rooms: [],
    loading: false,
    availableRooms: [],
  },
  extraReducers: {
    [getAllRooms.pending]: (state) => {
      state.loading = true;
    },
    [getAllRooms.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.rooms = payload.records;
    },
    [getAllRooms.rejected]: (state) => {
      state.loading = false;
    },
    [getAvailableRooms.pending]: (state) => {
      state.loading = true;
    },
    [getAvailableRooms.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.availableRooms = payload.records;
    },
    [getAvailableRooms.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default roomSlice.reducer;

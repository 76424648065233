import axios from "axios";

const token = window.localStorage.getItem("token");

const instance = axios.create({
  baseURL: "https://www.app.hotelsiraajinnapp.com/api",
  headers: {
    Accept: "application/json",
    Authorization: token ? `Bearer ${token}` : "",
  },
});

export default instance;

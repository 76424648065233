import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  getExpense,
  updateExpenses,
} from "../../../../../redux/features/salesAndExpenses/expensesSlice";

import ExpensesForm from "../ExpensesForm";
import Loading from "../../../../../helpers/loading";

import "./styles.css";

function ExpensesEdit() {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { expense, loading } = useSelector((state) => state.expenses);

  const onUpdate = (data) => {
    dispatch(updateExpenses({ data, id }));
    history.push("/sae/daily");
  };

  useEffect(() => {
    dispatch(getExpense(id));
  }, [dispatch, id]);

  return (
    <div className="expensesEdit">
      <div className="container__box">
        <h4>Add Expense Item</h4>
        {loading ? (
          <Loading />
        ) : (
          <ExpensesForm
            submit={onUpdate}
            buttonTitle="Update Expense"
            defaultValues={expense}
          />
        )}
      </div>
    </div>
  );
}

export default ExpensesEdit;

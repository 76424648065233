import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import axios from "../../../helpers/axios";

export const getCapitalInvestments = createAsyncThunk(
  "capitalInvestment/getCapitalInvestments",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axios.get("/capitalinvestment");

      dispatch(totalCapitalInvestment());

      return data;
    } catch (err) {
      toast.error("Capital Investments Load Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

export const getCapitalInvestment = createAsyncThunk(
  "capitalInvestment/getCapitalInvestment",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`capitalinvestment/view/${id}`);

      return data;
    } catch (err) {
      toast.error("Capital Investment Load Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

export const addCapitalInvestment = createAsyncThunk(
  "capitalInvestment/addCapitalInvestment",
  async ({ particulars, amount, remarks }, { rejectWithValue, dispatch }) => {
    try {
      await axios.post("/capitalinvestment/store", {
        particulars,
        amount,
        remarks,
      });

      dispatch(getCapitalInvestments());

      toast.success("Capital Investment Added!");
    } catch (err) {
      toast.error("Capital Investment Add Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

export const updateCapitalInvestment = createAsyncThunk(
  "capitalInvestment/updateCapitalInvestment",
  async (
    { data: { particulars, amount, remarks }, id },
    { rejectWithValue, dispatch }
  ) => {
    try {
      await axios.post(`/capitalinvestment/update/${id}`, {
        particulars,
        amount,
        remarks,
      });

      dispatch(getCapitalInvestments());

      toast.success("Capital Investment Updated!");
    } catch (err) {
      toast.error("Capital Investment Update Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteCapitalInvestment = createAsyncThunk(
  "capitalInvestment/deleteCapitalInvestment",
  async (uid, { rejectWithValue, dispatch }) => {
    try {
      await axios.post(`/capitalinvestment/delete/${uid}`);

      dispatch(getCapitalInvestments());

      toast.warn("Capital Investment Deleted!");
    } catch (err) {
      toast.error("Capital Investment Delete Failed!");
      
      return rejectWithValue(err.response.data);
    }
  }
);

export const totalCapitalInvestment = createAsyncThunk(
  "capitalInvestment/totalCapitalInvestment",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get("/total/capitalinvestment");

      return data;
    } catch (err) {
      toast.error("Total Capital Investment Load Failed");

      return rejectWithValue(err.response.data);
    }
  }
);

export const capitalInvestmentSlice = createSlice({
  name: "capitalInvestment",
  initialState: {
    investments: [],
    loading: false,
    investment: {},
    total: 0,
  },
  extraReducers: {
    [getCapitalInvestments.pending]: (state) => {
      state.loading = true;
    },
    [getCapitalInvestments.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.investments = payload.result.records;
    },
    [getCapitalInvestments.rejected]: (state) => {
      state.loading = false;
    },
    [getCapitalInvestment.pending]: (state) => {
      state.loading = true;
    },
    [getCapitalInvestment.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.investment = payload;
    },
    [getCapitalInvestment.rejected]: (state) => {
      state.loading = false;
    },
    [addCapitalInvestment.pending]: (state) => {
      state.loading = true;
    },
    [addCapitalInvestment.rejected]: (state) => {
      state.loading = false;
    },
    [updateCapitalInvestment.pending]: (state) => {
      state.loading = true;
    },
    [updateCapitalInvestment.rejected]: (state) => {
      state.loading = false;
    },
    [deleteCapitalInvestment.pending]: (state) => {
      state.loading = true;
    },
    [deleteCapitalInvestment.rejected]: (state) => {
      state.loading = false;
    },
    [totalCapitalInvestment.fulfilled]: (state, { payload }) => {
      state.total = payload.total;
    },
  },
});

export default capitalInvestmentSlice.reducer;

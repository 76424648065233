import React from "react";

import DeletedExpenses from "./deletedExpenses";

import DeletedSales from "./deletedSales";

function DeletedSAE() {
  return (
    <div className="daily">
      <div className="daily__box">
        <h4 className="daily__title">Deleted Sales And Expenses</h4>
        <div className="daily__groups">
          <div className="daily__sales">
            <DeletedSales />
          </div>
          <div className="daily__expenses">
            <DeletedExpenses />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeletedSAE;

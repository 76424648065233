export const availableRooms = [
  { value: "201", label: "201" },
  { value: "202", label: "202" },
  { value: "301", label: "301" },
  { value: "302", label: "302" },
  { value: "303", label: "303" },
  { value: "304", label: "304" },
  { value: "305", label: "305" },
  { value: "APT_1", label: "APT 1" },
  { value: "401", label: "401" },
  { value: "402", label: "402" },
  { value: "403", label: "403" },
  { value: "APT_2", label: "APT 2" },
  { value: "APT_3", label: "APT 3" },
];

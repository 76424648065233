import React from "react";
import { useRouteMatch } from "react-router-dom";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";

// nepali calendar
import { NepaliDatePicker } from "nepali-datepicker-reactjs";

function GuestForm({ submit, buttonTitle, defaultValues, availableRooms }) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ defaultValues });

  let match = useRouteMatch({
    path: `/guest/:guestID/edit/:rid`,
    strict: true,
    sensitive: true,
  });

  let match2 = useRouteMatch({
    path: "/guest/:guestID/booking/new",
    strict: true,
    sensitive: true,
  });

  return (
    <>
      <div>
        <span className="form__error">
          {errors.name?.type === "required" && "Name is required"}
        </span>
        <span className="form__error">
          {errors.phone_number?.type === "required" && "Phone is required"}
        </span>
        <span className="form__error">
          {errors.address?.type === "required" && "Address is required"}
        </span>
        <span className="form__error">
          {errors.adult_num?.type === "required" && "Adult Guest is required"}
        </span>
        <span className="form__error">
          {errors.rooms?.type === "required" &&
            "At least one room must be selected"}
        </span>
        <span className="form__error">
          {errors.check_in?.type === "required" && "Check In Date is required"}
        </span>
        <span className="form__error">
          {errors.expected_stay_days?.type === "required" &&
            "Stay Days is required"}
        </span>
        <span className="form__error">
          {errors.charge?.type === "required" && "Room Charge is required"}
        </span>
        <span className="form__error">
          {errors.guest_id?.type === "required" &&
            "Guest Identification Document is required"}
        </span>
        <br />
      </div>
      <form className="addGuest__form" onSubmit={handleSubmit(submit)}>
        <div className="addGuest__formDiv">
          <div className="addGuest__formLeft">
            <div className="addGuest__formField">
              <label htmlFor="name">
                Name <span>*</span>
              </label>
              <input
                type="text"
                id="name"
                placeholder="Guest Name"
                {...register("name", { required: true })}
              />
            </div>
            <div className="addGuest__formField">
              <label htmlFor="phone_number">
                Phone <span>*</span>
              </label>
              <input
                id="phone_number"
                type="number"
                placeholder="Guest Phone Number"
                {...register("phone_number", { required: true })}
              />
            </div>
            <div className="addGuest__formField">
              <label htmlFor="address">
                Address <span>*</span>
              </label>
              <input
                type="text"
                id="address"
                placeholder="Guest Address"
                {...register("address", { required: true })}
              />
            </div>
            <div className="addGuest__formField add_guest">
              <label htmlFor="guests">
                Guests <span>*</span>
              </label>
              <span>Adults</span>
              <input
                type="number"
                id="adult_num"
                defaultValue="1"
                className="addGuest__Guests"
                {...register("adult_num", { required: true })}
              />
              <span>Children</span>
              <input
                id="child_num"
                type="number"
                defaultValue="0"
                className="addGuest__Guests"
                {...register("child_num")}
              />
            </div>
            <div className="addGuest__formField">
              <label htmlFor="booked_from">Booked From</label>
              <input
                type="text"
                id="booked_from"
                placeholder="From Where booking is done"
                {...register("booked_from")}
              />
            </div>
            <div className="addGuest__formField">
              <label htmlFor="rooms">
                Room <span>*</span>
              </label>
              <Controller
                id="rooms"
                name="rooms"
                control={control}
                // defaultValue={[availableRooms[1]]}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    {...field}
                    isMulti
                    options={availableRooms}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                )}
              />
            </div>
            <div className="addGuest__formField">
              <label htmlFor="remarks">Remarks</label>
              <textarea
                id="remarks"
                rows="2"
                placeholder="Additional Remarks"
                {...register("remarks")}
              />
            </div>
          </div>
          <div className="addGuest__formRight">
            <div className="addGuest__formField">
              <label htmlFor="check_in">
                Check In <span>*</span>
              </label>
              <Controller
                name="check_in"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange } }) => (
                  <NepaliDatePicker
                    inputClassName="addGuest__calendar"
                    options={{ calenderLocale: "en", valueLocale: "en" }}
                    onChange={(value) => onChange(value)}
                  />
                )}
              />
            </div>
            <div className="addGuest__formField">
              <label htmlFor="expected_stay_days">
                Stay Days<span>*</span>
              </label>
              <input
                id="expected_stay_days"
                type="number"
                placeholder="Expected Stay Days"
                {...register("expected_stay_days", { required: true })}
              />
            </div>
            <div className="addGuest__formField">
              <label htmlFor="charge">
                Charge <span>*</span>
              </label>
              <input
                type="number"
                id="charge"
                placeholder="Total Charge Amount"
                {...register("charge", { required: true })}
              />
            </div>
            <div className="addGuest__formField">
              <label htmlFor="discount">Discount</label>
              <input
                type="number"
                id="discount"
                defaultValue="0"
                placeholder="Discounted Amount"
                {...register("discount")}
              />
            </div>
            <div className="addGuest__formField">
              <label htmlFor="advance_payment">Advance</label>
              <input
                type="number"
                id="advance_payment"
                defaultValue="0"
                placeholder="Advance Amount"
                {...register("advance_payment")}
              />
            </div>
            <div className="addGuest__formField">
              <label htmlFor="payment_status">
                Payment Status <span>*</span>
              </label>
              <input
                type="radio"
                id="payment_status"
                value="unpaid"
                {...register("payment_status")}
              />
              <span>Unpaid</span>
              <input
                type="radio"
                id="payment_status"
                value="partial"
                {...register("payment_status")}
              />
              <span>Partial</span>
              <input
                type="radio"
                id="payment_status"
                value="paid"
                {...register("payment_status")}
              />
              <span>Paid</span>
            </div>
            <div className="addGuest__formField">
              <label>
                Guest Id <span>*</span>
              </label>
              {match || match2 ? (
                <img
                  className="showGuestId"
                  src={defaultValues.guest_id}
                  alt="Guest Identification"
                />
              ) : (
                <input
                  type="file"
                  {...register("guest_id", { required: true })}
                />
              )}
            </div>
          </div>
        </div>
        <button className="addGuest__btn">{buttonTitle}</button>
      </form>
    </>
  );
}

export default GuestForm;

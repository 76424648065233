import React from "react";
import { useHistory } from "react-router";

// Redux
import { useDispatch } from "react-redux";
import { addExpenses } from "../../../../../redux/features/salesAndExpenses/expensesSlice";

import ExpensesForm from "../ExpensesForm";

import "./styles.css";

function ExpensesAdd() {
  const history = useHistory();
  const dispatch = useDispatch();
  const onAdd = (data) => {
    dispatch(addExpenses(data));

    history.push("/sae/daily");
  };

  return (
    <div className="expensesAdd">
      <div className="container__box">
        <h4>Add Expense Item</h4>
        <ExpensesForm submit={onAdd} buttonTitle="Add Expense" />
      </div>
    </div>
  );
}

export default ExpensesAdd;

import React, { useEffect } from "react";
import Notifying from "../../../components/notifying/Notifying";

import TelephoneLogoSvg from "../../../assets/images/telephone-logo.svg";

import "./Telephone.css";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  getTelephone,
  updateTelephone,
} from "../../../redux/features/utilities/telephoneSlice";

function Telephone() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTelephone());
  }, [dispatch]);

  const { notifyingDate, startDate, useDays, loading } = useSelector(
    (state) => state.telephone
  );
  
  return (
    <div className="telephone">
      <Notifying
        logo={TelephoneLogoSvg}
        notifyingDate={notifyingDate}
        startDate={startDate}
        useDays={useDays}
        update={updateTelephone}
        loading={loading}
      />
    </div>
  );
}

export default Telephone;

import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { getStaffDetails } from "../../../../../redux/features/utilities/staffSalarySlice";

import Salary from "../../salary";
import Loading from "../../../../../helpers/loading";

import "./styles.css";

function StaffDetails() {
  const { id } = useParams();

  const dispatch = useDispatch();
  const { staff, loading } = useSelector((state) => state.staffSalary);

  useEffect(() => {
    dispatch(getStaffDetails(id));
  }, [dispatch, id]);

  const { name, designation, joined_date, salary, phonenum, address } = staff;

  return (
    <div className="staffDetails">
      <div className="staffDetails__box">
        {loading ? (
          <Loading />
        ) : (
          <div className="staffDetails__info">
            <h3 className="staffDetails__title">Staff Details</h3>
            <div className="guestDetails__group">
              <h5>Name</h5>
              <span>{name}</span>
            </div>
            <div className="guestDetails__group">
              <h5>Designation</h5>
              <span>{designation}</span>
            </div>
            <div className="guestDetails__group">
              <h5>Joined Date</h5>
              <span>{joined_date}</span>
            </div>
            <div className="guestDetails__group">
              <h5>Phone No.</h5>
              <span>{phonenum}</span>
            </div>
            <div className="guestDetails__group">
              <h5>Address</h5>
              <span>{address}</span>
            </div>
            <div className="guestDetails__group">
              <h5>Salary</h5>
              <span>{salary}</span>
            </div>
          </div>
        )}
        <Salary />
      </div>
    </div>
  );
}

export default StaffDetails;

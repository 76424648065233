export const ADVANCE_COLUMNS = [
  {
    Header: "Id",
    accessor: "uid",
  },
  {
    Header: "Particulars",
    accessor: "advance",
  },
  {
    Header: "Amount",
    accessor: "amount",
  },
  {
    Header: "Date",
    accessor: "date",
  },
];

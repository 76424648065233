import React from "react";
import { useForm } from "react-hook-form";

function CapitalInvestmentForm({ submit, buttonTitle, defaultValues }) {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues });
  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className="form__text">
        <label htmlFor="particulars">Particulars</label>
        <input {...register("particulars", { required: true })} />
        <span className="form__error">
          {errors.particulars?.type === "required" && "Particulars is required"}
        </span>
      </div>
      <div className="form__text">
        <label htmlFor="amount">Amount</label>
        <input type="number" {...register("amount", { required: true })} />
        <span className="form__error">
          {errors.name?.type === "required" && "Amount is required"}
        </span>
      </div>
      <div className="form__text">
        <label htmlFor="remarks">Remarks</label>
        <textarea {...register("remarks")} rows="6" />
      </div>
      <button className="form__button--add">{buttonTitle}</button>
    </form>
  );
}

export default CapitalInvestmentForm;

import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  updateCreditRecord,
  getCreditRecord,
} from "../../../../redux/features/utilities/creditRecordsSlice";

import Loading from "../../../../helpers/loading";

import "../CreditRecord.css";
import CreditRecordForm from "../CreditRecordForm";

function CreditRecordEdit() {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { record, loading } = useSelector((state) => state.creditRecords);

  const onUpdate = (data) => {
    dispatch(updateCreditRecord({ data, id }));
    history.push("/utilities/credit");
  };

  useEffect(() => {
    dispatch(getCreditRecord(id));
  }, [dispatch, id]);

  return (
    <div className="creditRecordEdit">
      <div className="container__box">
        <h4>Edit Credit Record</h4>
        {loading ? (
          <Loading />
        ) : (
          <CreditRecordForm
            submit={onUpdate}
            defaultValues={record}
            buttonTitle="Edit"
          />
        )}
      </div>
    </div>
  );
}

export default CreditRecordEdit;

export const GUEST_DELETED_COLUMNS = [
  {
    Header: "Id",
    accessor: "rid",
  },
  {
    Header: "Phone",
    accessor: "phone_number",
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Check In",
    accessor: "check_in",
  },

  {
    Header: "Booked From",
    accessor: "booked_from",
  },
  {
    Header: "Charge",
    accessor: "charge",
  },
  {
    Header: "Advance",
    accessor: "advance_payment",
  },
  {
    Header: "Stay Days",
    accessor: "expected_stay_days",
  },
  {
    Header: "Total",
    accessor: "total",
  },
  {
    Header: "Credit",
    accessor: "credit",
  },
];

import React, { useState, useMemo, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import "./styles.css";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  deleteStaff,
  getAllStaff,
} from "../../../../../redux/features/utilities/staffSalarySlice";

import Table from "../../../../../components/table/Table";
import { STAFF_SALARY_COLUMNS } from "../staffColumns";
import Filter from "../../../../../components/filter/Filter";
import Loading from "../../../../../helpers/loading";

function StaffList() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { staffs, loading } = useSelector((state) => state.staffSalary);

  const [q, setQ] = useState("");
  const [searchColumns, setSearchColumns] = useState([
    "name",
    "designation",
    "joined_date",
  ]);

  const columnsHeader = useMemo(() => STAFF_SALARY_COLUMNS, []);
  const data = useMemo(() => staffs, [staffs]);

  let columnsTitle = data[0] && Object.keys(data[0]);

  useEffect(() => {
    dispatch(getAllStaff());
  }, [dispatch]);

  // Search Fields
  const filterColumns = [
    { name: "ID", value: "id" },
    { name: "Name", value: "name" },
    { name: "Designation", value: "designation" },
    { name: "Joined Date", value: "joined_date" },
  ];

  function search(rows) {
    return rows.filter((row) =>
      searchColumns.some(
        (column) =>
          row[column]?.toString().toLowerCase().indexOf(q.toLowerCase()) > -1
      )
    );
  }

  const onEdit = (uid) => {
    history.push(`/utilities/staff/edit/${uid}`);
  };

  const onDelete = (uid) => {
    dispatch(deleteStaff(uid));
  };

  return (
    <div className="staffList">
      <div className="g-list__box">
        <div className="g-list__heading">
          <h2 className="g-list__title">STAFF</h2>
          <Link to="/utilities/staff/add" className="g-list__actions">
            + New Staff
          </Link>
        </div>

        <Filter
          q={q}
          setQ={setQ}
          filterColumns={filterColumns}
          searchColumns={searchColumns}
          setSearchColumns={setSearchColumns}
        />

        <div className="g-list__details">
          {loading ? (
            <Loading />
          ) : (
            <Table
              columns={columnsHeader}
              data={search(data)}
              columnsTitle={columnsTitle}
              link="staff/details"
              onEdit={onEdit}
              onDelete={onDelete}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default StaffList;

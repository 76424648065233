import React, { useState, useMemo, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import Loading from "../../../helpers/loading";

import "./styles.css";
import Table from "../../../components/table/Table";

import { COLUMNS } from "./Column";

import Filter from "../../../components/filter/Filter";

// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  deleteReservation,
  getAllReservations,
} from "../../../redux/features/reservation/reservationSlice";

function ReservationList() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { reservations, loading } = useSelector((state) => state.reservation);

  const [q, setQ] = useState("");
  const [searchColumns, setSearchColumns] = useState(["name", "phone"]);

  useEffect(() => {
    dispatch(getAllReservations());
  }, [dispatch]);

  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => reservations, [reservations]);

  let columnsTitle = data[0] && Object.keys(data[0]);

  // Remove room no and remarks field from table
  columnsTitle = columnsTitle?.filter(
    (columnTitle) =>
      columnTitle === "rid" ||
      columnTitle === "phone_number" ||
      columnTitle === "name" ||
      columnTitle === "address" ||
      columnTitle === "rooms" ||  
      columnTitle === "check_in" ||
      columnTitle === "expected_stay_days" ||
      columnTitle === "booked_from"
  );

  // Search Fields
  const filterColumns = [
    { name: "ID", value: "rid" },
    { name: "Phone", value: "phone_number" },
    { name: "Name", value: "name" },
    { name: "Address", value: "address" },
    { name: "Check In", value: "check_in" },
    { name: "Stay Days", value: "expected_stay_days" },
    { name: "Booked From", value: "booked_from" },
  ];

  function search(rows) {
    return rows.filter((row) =>
      searchColumns.some(
        (column) =>
          row[column]?.toString().toLowerCase().indexOf(q.toLowerCase()) > -1
      )
    );
  }

  const onEdit = (rid) => {
    history.push(`/reservation/edit/${rid}`);
  };

  const onDelete = (rid) => {
    dispatch(deleteReservation(rid));
  };

  return (
    <div className="reservationList container">
      <div className="g-list__box">
        <div className="g-list__heading">
          <h2 className="g-list__title">Reservations</h2>
          <Link to="/reservation/add" className="g-list__actions">
            + New Reservation
          </Link>
        </div>

        <Filter
          q={q}
          setQ={setQ}
          filterColumns={filterColumns}
          searchColumns={searchColumns}
          setSearchColumns={setSearchColumns}
        />

        <div className="g-list__details">
          {loading ? (
            <Loading />
          ) : (
            <Table
              columns={columns}
              data={search(data)}
              columnsTitle={columnsTitle}
              link="details"
              onEdit={onEdit}
              onDelete={onDelete}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default ReservationList;

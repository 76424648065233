import React from "react";
import { useHistory } from "react-router";

// Redux
import { useDispatch } from "react-redux";
import { addSales } from "../../../../../redux/features/salesAndExpenses/salesSlice";

import SalesForm from "../SalesForm";

import "./styles.css";

function SalesAdd() {
  const history = useHistory();
  const dispatch = useDispatch();

  const onAdd = (data) => {
    dispatch(addSales(data));

    history.push("/sae/daily");
  };

  return (
    <div className="salesAdd">
      <div className="container__box">
        <h4>Add Sales Item</h4>
        <SalesForm submit={onAdd} buttonTitle="Add Sale" />
      </div>
    </div>
  );
}

export default SalesAdd;

import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { addGuest } from "../../../redux/features/guest/guestSlice";
import { getAvailableRooms } from "../../../redux/features/room/roomSlice";

import Loading from "../../../helpers/loading";

import GuestForm from "../GuestForm";

import "./styles.css";

function AddGuest() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.guest);
  const { availableRooms } = useSelector((state) => state.room);

  useEffect(() => {
    dispatch(getAvailableRooms());
  }, [dispatch]);

  const add = (data) => {
    dispatch(addGuest(data));

    history.push("/guest/list");
  };

  return (
    <div className="addGuest">
      <div className="addGuest__box">
        <h4 className="addGuest__title">Add Guest</h4>
        {loading ? (
          <Loading />
        ) : (
          <GuestForm
            submit={add}
            buttonTitle="Check In"
            availableRooms={availableRooms}
          />
        )}
      </div>
    </div>
  );
}

export default AddGuest;

import React, { useState, useMemo, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  getCreditRecords,
  deleteCreditRecord,
} from "../../../../redux/features/utilities/creditRecordsSlice";

import "../CreditRecord.css";

import Table from "../../../../components/table/Table";
import { CREDIT_RECORD_COLUMNS } from "./creditColumns";

import Loading from "../../../../helpers/loading";
import Filter from "../../../../components/filter/Filter";

function CreditRecordList() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { records, loading } = useSelector((state) => state.creditRecords);

  const [q, setQ] = useState("");
  const [searchColumns, setSearchColumns] = useState(["name", "phone"]);

  const columnsHeader = useMemo(() => CREDIT_RECORD_COLUMNS, []);
  const data = useMemo(() => records, [records]);

  useEffect(() => {
    dispatch(getCreditRecords());
  }, [dispatch]);

  let columnsTitle = data[0] && Object.keys(data[0]);

  // Search Fields
  const filterColumns = [
    { name: "ID", value: "uid" },
    { name: "Name", value: "name" },
    { name: "Address", value: "address" },
    { name: "Phone", value: "phone" },
    { name: "Paid", value: "paid" },
  ];

  function search(rows) {
    return rows.filter((row) =>
      searchColumns.some(
        (column) =>
          row[column]?.toString().toLowerCase().indexOf(q.toLowerCase()) > -1
      )
    );
  }

  const onEdit = (uid) => {
    history.push(`/utilities/credit/edit/${uid}`);
  };

  const onDelete = (uid) => {
    dispatch(deleteCreditRecord(uid));
  };

  return (
    <div className="creditRecordList">
      <div className="g-list__box">
        <div className="g-list__heading">
          <h2 className="g-list__title">CREDIT RECORDS</h2>
          <Link to="/utilities/credit/add" className="g-list__actions">
            + New Credit
          </Link>
        </div>

        <Filter
          q={q}
          setQ={setQ}
          filterColumns={filterColumns}
          searchColumns={searchColumns}
          setSearchColumns={setSearchColumns}
        />

        <div className="g-list__details">
          {loading ? (
            <Loading />
          ) : (
            <Table
              columns={columnsHeader}
              data={search(data)}
              columnsTitle={columnsTitle}
              link=""
              onEdit={onEdit}
              onDelete={onDelete}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default CreditRecordList;

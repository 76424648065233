import React from 'react'

function HotelInformation() {
    return (
        <div className="hotelInformation">
            Hotel Important Information
        </div>
    )
}

export default HotelInformation

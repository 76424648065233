import React, { useEffect } from "react";
import { Doughnut } from "react-chartjs-2";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { getInventoryReport } from "../../../redux/features/utilities/inventorySlice";

import AdvanceList from "./advance/list";
import CostList from "./cost/list";

import "./Inventory.css";

import Loading from "../../../helpers/loading";

function Inventory() {
  const dispatch = useDispatch();
  const { inventoryReport, loading, isProfit, profit, loss } = useSelector(
    (state) => state.inventory
  );

  useEffect(() => {
    dispatch(getInventoryReport());
  }, [dispatch]);

  return (
    <div className="inventory">
      <div className="inventory__box">
        <h4 className="inventory__title">Inventory</h4>
        <div className="inventory__groups">
          <div className="inventory__advance">
            <AdvanceList />
          </div>
          {loading ? (
            <Loading />
          ) : (
            <div className="daily__chart">
              <div
                className={`daily__result ${
                  isProfit ? "daily__result--profit" : "daily__result--loss"
                }`}
              >
                <h5>
                  {isProfit ? "Profit" : "Loss"} : {isProfit ? profit : loss}
                </h5>
              </div>
              <Doughnut data={inventoryReport} />
            </div>
          )}
          <div className="inventory__cost">
            <CostList />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Inventory;

import React from "react";
import "./SMSModal.css";

function SMSModal({
  toggleModal,
  send,
  title,
  setMsg,
  saveInfo,
  onSaveInfo,
  content,
}) {
  return (
    <div className="modal">
      <div className="SMSModal">
        <img
          src={require("../../../assets/icons/cross-icon.png").default}
          alt=""
          className="SMSModal__cancelBtn"
          onClick={toggleModal}
        />
        <div className="SMSModal__header">
          <h3 className="SMSModal__title">SMS {title}</h3>
          {saveInfo && (
            <button className="SMSModal__saveBtn" onClick={onSaveInfo}>
              Save Info
            </button>
          )}
        </div>
        <form onSubmit={send}>
          <textarea
            rows="15"
            placeholder={`Enter ${title} content here`}
            className="SMSModal__description"
            value={content}
            onChange={(e) => setMsg(e.target.value)}
          ></textarea>
          <button className="SMSModal__sendBtn">Send {title}</button>
        </form>
      </div>
    </div>
  );
}

export default SMSModal;

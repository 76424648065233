import React from "react";
import { useHistory } from "react-router";

// Redux
import { useDispatch } from "react-redux";
import { addCost } from "../../../../../redux/features/utilities/inventorySlice";

import CostForm from "../CostForm";

import "./styles.css";

function CostAdd() {
  const history = useHistory();
  const dispatch = useDispatch();

  const defaultValues = {
    uid: "",
    advance: "",
    amount: 0,
    date: "",
  };

  const onAdd = (data) => {
    dispatch(addCost(data));
    history.push("/utilities/inventory");
  };

  return (
    <div className="costAdd">
      <div className="container__box">
        <h4>Add Inventory Expense</h4>

        <CostForm
          defaultValues={defaultValues}
          submit={onAdd}
          buttonTitle="Add"
        />
      </div>
    </div>
  );
}

export default CostAdd;

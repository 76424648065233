import React, { useState, useMemo, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCapitalInvestment,
  getCapitalInvestments,
  totalCapitalInvestment,
} from "../../../../redux/features/utilities/capitalInvestmentSlice";

import "../CapitalInvestment.css";

import { CAPITAL_INVESTMENT_COLUMNS } from "./columns";
import Table from "../../../../components/table/Table";

import Loading from "../../../../helpers/loading";
import Filter from "../../../../components/filter/Filter";

function CapitalInvestmentList() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { investments, loading, total } = useSelector(
    (state) => state.capitalInvestment
  );

  const [q, setQ] = useState("");
  const [searchColumns, setSearchColumns] = useState(["particulars", "amount"]);

  const columnsHeader = useMemo(() => CAPITAL_INVESTMENT_COLUMNS, []);
  const data = useMemo(() => investments, [investments]);

  useEffect(() => {
    dispatch(getCapitalInvestments());
    dispatch(totalCapitalInvestment());
  }, [dispatch]);

  let columnsTitle = data[0] && Object.keys(data[0]);

  // Search Fields
  const filterColumns = [
    { name: "ID", value: "uid" },
    { name: "Particulars", value: "particulars" },
    { name: "Amount", value: "amount" },
    { name: "Remarks", value: "remarks" },
  ];

  function search(rows) {
    return rows.filter((row) =>
      searchColumns.some(
        (column) =>
          row[column]?.toString().toLowerCase().indexOf(q.toLowerCase()) > -1
      )
    );
  }

  const onEdit = (uid) => {
    history.push(`/utilities/investment/edit/${uid}`);
  };

  const onDelete = (uid) => {
    dispatch(deleteCapitalInvestment(uid));
  };

  return (
    <div className="capitalInvestmentList">
      <div className="g-list__box">
        <div className="g-list__heading">
          <h2 className="g-list__title">CAPITAL INVESTMENT</h2>
          <Link to="/utilities/investment/add" className="g-list__actions">
            + New Investment
          </Link>
        </div>

        <Filter
          q={q}
          setQ={setQ}
          filterColumns={filterColumns}
          searchColumns={searchColumns}
          setSearchColumns={setSearchColumns}
        />
        <h5 className="capitalInvestmentList__total">
          TOTAL CAPITAL INVESTMENT : {total}
        </h5>

        {loading ? (
          <Loading />
        ) : (
          <div className="capitalInvestmentList__details ">
            <Table
              columns={columnsHeader}
              data={search(data)}
              columnsTitle={columnsTitle}
              link=""
              onEdit={onEdit}
              onDelete={onDelete}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default CapitalInvestmentList;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import axios from "../../../helpers/axios";

export const getRent = createAsyncThunk(
  "rent/getRent",
  async ({ year }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/rents/${year}`);

      const {
        data: { results },
      } = response;

      if (results === null) {
        return {
          baisakh: {
            paid: 0,
            remarks: null,
          },
          jestha: {
            paid: 0,
            remarks: null,
          },
          asaar: {
            paid: 0,
            remarks: null,
          },
          shraun: {
            paid: 0,
            remarks: null,
          },
          bhadra: {
            paid: 0,
            remarks: null,
          },
          ashwin: {
            paid: 0,
            remarks: null,
          },
          kartik: {
            paid: 0,
            remarks: null,
          },
          mangsir: {
            paid: 0,
            remarks: null,
          },
          poush: {
            paid: 0,
            remarks: null,
          },
          magh: {
            paid: 0,
            remarks: null,
          },
          falgun: {
            paid: 0,
            remarks: null,
          },
          chaitra: {
            paid: 0,
            remarks: null,
          },
        };
      }

      return results;
    } catch (err) {
      toast.error("Rent Load Failed!")

      return rejectWithValue(err.response.data);
    }
  }
);

export const setRent = createAsyncThunk(
  "rent/setRent",
  async ({ year, months }, { rejectWithValue, dispatch }) => {
    try {
      await axios.post(`/rents/${year}`, { months: months });

      toast.success("Rent Saved!");
      
      dispatch(getRent({ year }));
    } catch (err) {
      toast.error("Rent Save Failed!");
      
      return rejectWithValue(err.response.data);
    }
  }
);

export const rentSlice = createSlice({
  name: "rent",
  initialState: {
    year: "2077",
    rootMonths: {},
    loading: false,
  },
  reducers: {
    changeYear: (state, { payload }) => {
      state.year = payload;
    },
  },
  extraReducers: {
    [getRent.pending]: (state) => {
      state.loading = true;
    },
    [getRent.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.rootMonths = payload;
    },
    [getRent.rejected]: (state) => {
      state.loading = false;
    },
    [setRent.pending]: (state) => {
      state.loading = true;
    },
    [setRent.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const { changeYear } = rentSlice.actions;

export default rentSlice.reducer;

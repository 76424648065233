export const COLUMNS = [
  {
    Header: "Id",
    accessor: "rid",
  },
  {
    Header: "Phone",
    accessor: "phone_number",
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Address",
    accessor: "address",
  },

  {
    Header: "Check In",
    accessor: "check_in",
  },
  {
    Header: "Booked From",
    accessor: "booked_from",
  },
  {
    Header: "Stay Days",
    accessor: "expected_stay_days",
  },
  {
    Header: "Rooms",
    accessor: "rooms",
  },
];

import { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Zoom, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import PrivateRoute from "./routing/PrivateRoute";

import "./App.css";

import Loading from "./helpers/loading";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { loadUser } from "./redux/features/app/appSlice";

import Login from "./pages/auth/login/Login";
import Home from "./pages/home.js/Home";

import Tv from "./pages/notifying/tv/Tv";
import Water from "./pages/notifying/water/Water";
import Electricity from "./pages/notifying/electricity/Electricity";
import Telephone from "./pages/notifying/telephone/Telephone";
import Policies from "./pages/policies/Policies";
import PoliciesEdit from "./pages/policies/edit";
import Utilities from "./pages/utilities/Utilities";

// Guests
import AddGuest from "./pages/guest/add";
import GuestExist from "./pages/guest/exist";
import GuestEdit from "./pages/guest/edit";
import GuestList from "./pages/guest/list";
import GuestDetails from "./pages/guest/details";
import GuestDeleted from "./pages/guest/deleted";

// Reservations
import ReservationAdd from "./pages/reservation/add";
import ReservationEdit from "./pages/reservation/edit";
import ReservationList from "./pages/reservation/list";
import ReservationDetails from "./pages/reservation/details";
import ReservationCheckIn from "./pages/reservation/checkin";
import FrontReservation from "./pages/reservation/front";

// 404
import PageNotFound from "./pages/PageNotFound/404";

import DailySAE from "./pages/utilities/salesAndExpenses/daily/Daily";
import MonthlySAE from "./pages/utilities/salesAndExpenses/monthly/Monthly";
import DeletedSAE from "./pages/utilities/salesAndExpenses/deleted";

import HotelInformation from "./pages/utilities/hotelInformation/HotelInformation";

// Rent
import RentList from "./pages/utilities/rent/list/RentList";

// Capital Investment
import CapitalInvestmentList from "./pages/utilities/capitalInvestment/list";
import CapitalInvestmentAdd from "./pages/utilities/capitalInvestment/add";
import CapitalInvestmentEdit from "./pages/utilities/capitalInvestment/edit";

// Inventory
import Inventory from "./pages/utilities/inventory/Inventory";

// Advance
import AdvanceAdd from "./pages/utilities/inventory/advance/add";
import AdvanceEdit from "./pages/utilities/inventory/advance/edit";

// Cost
import CostAdd from "./pages/utilities/inventory/cost/add";
import CostEdit from "./pages/utilities/inventory/cost/edit";

// Credit Records
import CreditRecordList from "./pages/utilities/creditRecord/list";
import CreditRecordAdd from "./pages/utilities/creditRecord/add";
import CreditRecordEdit from "./pages/utilities/creditRecord/edit";

// Staff Salary
import StaffList from "./pages/utilities/staffSalary/staff/list";
import StaffAdd from "./pages/utilities/staffSalary/staff/add";
import StaffEdit from "./pages/utilities/staffSalary/staff/edit";
import StaffDetails from "./pages/utilities/staffSalary/staff/details";

// Sales
import SalesAdd from "./pages/utilities/salesAndExpenses/sales/add";
import SalesEdit from "./pages/utilities/salesAndExpenses/sales/edit";
import SalesMonthlyList from "./pages/utilities/salesAndExpenses/sales/monthlyList";

// Expenses
import ExpensesAdd from "./pages/utilities/salesAndExpenses/expenses/add";
import ExpensesEdit from "./pages/utilities/salesAndExpenses/expenses/edit";
import ExpensesMonthlyList from "./pages/utilities/salesAndExpenses/expenses/monthlyList";

// Profile
import Profile from "./pages/auth/profile/Profile";

function App() {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.app);

  useEffect(() => {
    dispatch(loadUser());
  }, [dispatch]);

  return (
    <div className="app">
      <ToastContainer
        autoClose={1500}
        hideProgressBar={true}
        transition={Zoom}
      />
      <Router>
        {loading ? (
          <div className="app__loading">
            <Loading />
          </div>
        ) : (
          <Switch>
            {/* User Profile */}
            <PrivateRoute exact path="/user/profile">
              <Profile />
            </PrivateRoute>

            {/* Guests */}
            <PrivateRoute exact path="/guest/add">
              <AddGuest />
            </PrivateRoute>
            <PrivateRoute exact path="/guest/:guestID/booking/new">
              <GuestExist />
            </PrivateRoute>
            <PrivateRoute exact path="/guest/list">
              <GuestList />
            </PrivateRoute>
            <PrivateRoute exact path="/guest/details/:guestID">
              <GuestDetails />
            </PrivateRoute>
            <PrivateRoute exact path="/guest/:guestID/edit/:rid">
              <GuestEdit />
            </PrivateRoute>
            <PrivateRoute exact path="/guest/deleted">
              <GuestDeleted />
            </PrivateRoute>

            {/* Reservations */}
            <PrivateRoute exact path="/reservation/add">
              <ReservationAdd />
            </PrivateRoute>
            <PrivateRoute exact path="/reservation/edit/:id">
              <ReservationEdit />
            </PrivateRoute>
            <PrivateRoute exact path="/reservation/list">
              <ReservationList />
            </PrivateRoute>
            <PrivateRoute exact path="/reservation/details/:id">
              <ReservationDetails />
            </PrivateRoute>
            <PrivateRoute exact path="/reservation/checkin/:id">
              <ReservationCheckIn />
            </PrivateRoute>
            <PrivateRoute exact path="/reservation/reserved/:roomid">
              <FrontReservation />
            </PrivateRoute>

            {/* Utilities */}
            <PrivateRoute exact path="/utilities">
              <Utilities />
            </PrivateRoute>

            {/* Services */}
            <PrivateRoute exact path="/services/water">
              <Water />
            </PrivateRoute>
            <PrivateRoute exact path="/services/tv">
              <Tv />
            </PrivateRoute>
            <PrivateRoute exact path="/services/electricity">
              <Electricity />
            </PrivateRoute>
            <PrivateRoute exact path="/services/telephone">
              <Telephone />
            </PrivateRoute>

            {/* Rent */}
            <PrivateRoute exact path="/utilities/rent">
              <RentList />
            </PrivateRoute>

            {/* Credit Records */}
            <PrivateRoute exact path="/utilities/credit">
              <CreditRecordList />
            </PrivateRoute>
            <PrivateRoute exact path="/utilities/credit/add">
              <CreditRecordAdd />
            </PrivateRoute>
            <PrivateRoute exact path="/utilities/credit/edit/:id">
              <CreditRecordEdit />
            </PrivateRoute>

            {/* Staff Salary */}
            <PrivateRoute exact path="/utilities/staff">
              <StaffList />
            </PrivateRoute>
            <PrivateRoute exact path="/utilities/staff/add">
              <StaffAdd />
            </PrivateRoute>
            <PrivateRoute exact path="/utilities/staff/edit/:id">
              <StaffEdit />
            </PrivateRoute>
            <PrivateRoute exact path="/utilities/staff/details/:id">
              <StaffDetails />
            </PrivateRoute>

            {/* Sales And Expenses */}
            <PrivateRoute exact path="/sae/daily">
              <DailySAE />
            </PrivateRoute>
            <PrivateRoute exact path="/utilities/sae/monthly">
              <MonthlySAE />
            </PrivateRoute>
            <PrivateRoute exact path="/utilities/sae/monthly/sales">
              <SalesMonthlyList />
            </PrivateRoute>
            <PrivateRoute exact path="/utilities/sae/monthly/expenses">
              <ExpensesMonthlyList />
            </PrivateRoute>
            <PrivateRoute exact path="/utilities/sae/sales/add">
              <SalesAdd />
            </PrivateRoute>
            <PrivateRoute exact path="/utilities/sae/sales/edit/:id">
              <SalesEdit />
            </PrivateRoute>
            <PrivateRoute exact path="/utilities/sae/expenses/add">
              <ExpensesAdd />
            </PrivateRoute>
            <PrivateRoute exact path="/utilities/sae/expenses/edit/:id">
              <ExpensesEdit />
            </PrivateRoute>
            <PrivateRoute exact path="/sae/deleted">
              <DeletedSAE />
            </PrivateRoute>

            {/* Capital Investment */}
            <PrivateRoute exact path="/utilities/investment">
              <CapitalInvestmentList />
            </PrivateRoute>
            <PrivateRoute exact path="/utilities/investment/add">
              <CapitalInvestmentAdd />
            </PrivateRoute>
            <PrivateRoute exact path="/utilities/investment/edit/:id">
              <CapitalInvestmentEdit />
            </PrivateRoute>

            {/* Inventory */}
            <PrivateRoute exact path="/utilities/inventory">
              <Inventory />
            </PrivateRoute>

            {/* Advance */}
            <PrivateRoute exact path="/utilities/inventory/advance/add">
              <AdvanceAdd />
            </PrivateRoute>
            <PrivateRoute exact path="/utilities/inventory/advance/edit/:id">
              <AdvanceEdit />
            </PrivateRoute>

            {/* Cost */}
            <PrivateRoute exact path="/utilities/inventory/cost/add">
              <CostAdd />
            </PrivateRoute>
            <PrivateRoute exact path="/utilities/inventory/cost/edit/:id">
              <CostEdit />
            </PrivateRoute>

            <PrivateRoute exact path="/utilities/hotelInformation">
              <HotelInformation />
            </PrivateRoute>

            <Route exact path="/policies">
              <Policies />
            </Route>

            <Route exact path="/policies/edit">
              <PoliciesEdit />
            </Route>

            {/* Login */}
            <Route exact path="/login">
              <Login />
            </Route>

            {/* Home */}
            <PrivateRoute exact path="/">
              <Home />
            </PrivateRoute>

            {/* Page Not Found */}
            <Route to="*">
              <PageNotFound />
            </Route>
          </Switch>
        )}
      </Router>
    </div>
  );
}

export default App;

export const CAPITAL_INVESTMENT_COLUMNS = [
  {
    Header: "Id",
    accessor: "uid",
  },
  {
    Header: "Particulars",
    accessor: "particulars",
  },
  {
    Header: "Amount",
    accessor: "amount",
  },
  {
    Header: "Remarks",
    accessor: "remarks",
  },
];

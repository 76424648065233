import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../../helpers/axios";

import { todayBS } from "../../../helpers/nepaliDate";

export const getDailySales = createAsyncThunk(
  "sales/getDailySales",
  async ({ from = todayBS, to = todayBS }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/dailysales?from=${from}&&to=${to}`);

      return data;
    } catch (err) {
      toast.error("Sales Load Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

export const getSale = createAsyncThunk(
  "sales/getSale",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/dailysales/view/${id}`);

      return data;
    } catch (err) {
      toast.error("Sale Load Failed!");

      return rejectWithValue(err);
    }
  }
);

export const addSales = createAsyncThunk(
  "sales/addSales",
  async (
    { name, amount, description, date },
    { rejectWithValue, dispatch }
  ) => {
    try {
      await axios.post("/dailysales/store", {
        name,
        amount,
        description,
        date,
      });

      dispatch(getDailySales({ from: todayBS, to: todayBS }));
      dispatch(getDailyReport());

      toast.success("Sale Added!");
    } catch (err) {
      toast.error("Sale Add Failed!");

      return rejectWithValue(err);
    }
  }
);

export const updateSales = createAsyncThunk(
  "sales/updateSales",
  async (
    { data: { name, amount, description, date }, id },
    { rejectWithValue, dispatch }
  ) => {
    try {
      await axios.post(`/dailysales/update/${id}`, {
        name,
        amount,
        description,
        date,
      });

      dispatch(getDailySales({ from: todayBS, to: todayBS }));
      dispatch(getDailyReport());

      toast.success("Sale Updated!");
    } catch (err) {
      toast.error("Sale Update Failed!");

      return rejectWithValue(err);
    }
  }
);

export const deleteSales = createAsyncThunk(
  "sales/deleteSales",
  async (uid, { rejectWithValue, dispatch }) => {
    try {
      await axios.post(`/dailysales/delete/${uid}`);

      dispatch(getDailySales({ from: todayBS, to: todayBS }));
      dispatch(getDailyReport());

      toast.warn("Sale Deleted!");
    } catch (err) {
      toast.error("Sale Delete Failed!");

      return rejectWithValue(err);
    }
  }
);

export const getDailyReport = createAsyncThunk(
  "sales/getDailyReport",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get("/dailytotal");

      let report = {
        labels: ["Daily Sales", "Daily Expenses"],
        datasets: [
          {
            label: "Daily Sales And Expenses",
            data: [data?.result?.sales, data?.result?.expenses],
            backgroundColor: ["rgba(75, 192, 192, 1)", "rgba(255, 99, 132, 1)"],
            borderColor: ["rgba(75, 192, 192, 1)", "rgba(255, 99, 132, 1)"],
            borderWidth: 1,
          },
        ],
      };

      let isProfit = false;
      let profit = 0;
      let loss = 0;

      if (
        parseFloat(data?.result?.sales) > parseFloat(data?.result?.expenses)
      ) {
        isProfit = true;
        profit = data?.result?.sales - data?.result?.expenses;
      } else {
        isProfit = false;
        loss = data?.result?.expenses - data?.result?.sales;
      }

      return { report, isProfit, loss, profit };
    } catch (err) {
      toast.error("Daily Report Load Failed!");

      return rejectWithValue(err);
    }
  }
);

export const getMonthlyReport = createAsyncThunk(
  "sales/getMonthlyReport",
  async ({ fromDate = todayBS, toDate = todayBS }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `/monthlysalesexpenses?from=${fromDate}&&to=${toDate}`
      );

      const salesData = Object.values(data.sales).map((sale) => {
        return sale.amount;
      });

      const expensesData = Object.values(data.expenses).map((expense) => {
        return expense.amount;
      });

      let isProfit = false;
      let profit = 0;
      let loss = 0;

      if (parseFloat(data?.profit) > parseFloat(data?.loss)) {
        isProfit = true;
        profit = data?.profit - data?.loss;
      } else {
        isProfit = false;
        loss = data?.loss - data?.profit;
      }

      let report = {
        labels: Object.keys(data.sales),
        datasets: [
          {
            label: "Expenses",
            data: expensesData,
            fill: false,
            backgroundColor: "rgb(255, 99, 132)",
            borderColor: "rgb(255, 99, 132, 0.5)",
          },
          {
            label: "Sales",
            data: salesData,
            fill: false,
            backgroundColor: "rgba(75, 192, 192, 1)",
            borderColor: "rgba(75, 192, 192, 0.5)",
          },
        ],
      };

      return { report, isProfit, loss, profit };

    } catch (err) {
      toast.error("Monthly Report Load Failed!");

      return rejectWithValue(err);
    }
  }
);

export const getDeletedSales = createAsyncThunk(
  "sales/getDeletedSales",
  async ({ from = todayBS, to = todayBS }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/dailysales?from=${from}&&to=${to}&&history=1`);

      // console.log(data)

      return data;
    } catch (err) {
      toast.error("Deleted Sales Load Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

export const salesSlice = createSlice({
  name: "sales",
  initialState: {
    records: [],
    loading: false,
    sale: {},
    dailyReport: {},
    monthlyReport: {},
    isProfit: false,
    profit: 0,
    loss: 0,
  },
  extraReducers: {
    [getDailySales.pending]: (state) => {
      state.loading = true;
    },
    [getDailySales.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.records = payload.result.records;
    },
    [getDailySales.rejected]: (state) => {
      state.loading = false;
    },
    [getSale.pending]: (state) => {
      state.loading = true;
    },
    [getSale.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.sale = payload;
    },
    [getSale.rejected]: (state) => {
      state.loading = false;
    },
    [addSales.pending]: (state) => {
      state.loading = true;
    },
    [addSales.rejected]: (state) => {
      state.loading = false;
    },
    [updateSales.pending]: (state) => {
      state.loading = true;
    },
    [updateSales.rejected]: (state) => {
      state.loading = false;
    },
    [deleteSales.pending]: (state) => {
      state.loading = true;
    },
    [deleteSales.rejected]: (state) => {
      state.loading = false;
    },
    [getDailyReport.pending]: (state) => {
      state.loading = true;
    },
    [getDailyReport.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.dailyReport = payload.report;
      state.isProfit = payload.isProfit;
      state.profit = payload.profit;
      state.loss = payload.loss;
    },
    [getDailyReport.rejected]: (state) => {
      state.loading = false;
      state.dailyReport = {};
      state.isProfit = false;
      state.profit = 0;
      state.loss = 0;
    },
    [getMonthlyReport.pending]: (state) => {
      state.loading = true;
    },
    [getMonthlyReport.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.monthlyReport = payload.report;
      state.isProfit = payload.isProfit;
      state.profit = payload.profit;
      state.loss = payload.loss;
    },
    [getMonthlyReport.rejected]: (state) => {
      state.loading = false;
    },
    [getDeletedSales.pending]: (state) => {
      state.loading = true;
    },
    [getDeletedSales.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.records = payload.result.records;
    },
    [getDeletedSales.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default salesSlice.reducer;

import React, { useState } from "react";
import { Redirect } from "react-router";

// Toast
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// redux
import { useSelector, useDispatch } from "react-redux";
import { loginUser } from "../../../redux/features/app/appSlice";

import "./Login.css";

function Login() {
  const { auth } = useSelector((state) => state.app);
  const dispatch = useDispatch();

  const [login, setLogin] = useState({ email: "", password: "" });

  const handleChange = (e) => {
    setLogin((prevLogin) => ({
      ...prevLogin,
      [e.target.name]: e.target.value,
    }));
  };

  const handleLogin = (e) => {
    e.preventDefault();
    dispatch(loginUser(login));
  };

  const { email, password } = login;

  return auth ? (
    <Redirect to="/" />
  ) : (
    <div className="login">
      <div className="login__box">
        <img
          src={require("../../../assets/images/logo.png").default}
          alt=""
          className="login__logo"
        />
        <h3 className="login__hotel">Hotel Siraaj Inn</h3>
        <h3 className="login__title">LOGIN</h3>
        <form className="login__form">
          <div className="login__formField">
            <input
              type="text"
              placeholder="Enter email"
              name="email"
              value={email}
              onChange={handleChange}
            />
          </div>
          <div className="login__formField">
            <input
              type="password"
              placeholder="Enter password"
              name="password"
              value={password}
              onChange={handleChange}
            />
          </div>
          <button type="submit" onClick={handleLogin}>
            LOGIN
          </button>
        </form>
      </div>
    </div>
  );
}

export default Login;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getHotelPolicies } from "../../redux/features/utilities/hotelpoliciesSlice";

import Loading from "../../helpers/loading";

import "./Policies.css";

function Policies() {
  const dispatch = useDispatch();
  const { description, loading } = useSelector((state) => state.hotelPolicies);

  useEffect(() => {
    dispatch(getHotelPolicies());
  }, [dispatch]);

  return (
    <div className="policies">
      <div className="policies__box">
        {loading ? (
          <Loading />
        ) : (
          <>
            <Link to="/" className="policies__goHome">
              Go Home
            </Link>
            <Link to="/policies/edit" className="policies__edit">
              Edit
            </Link>
            <div className="policies__title">HOTEL POLICIES</div>
            <div
              className="policies__content"
              dangerouslySetInnerHTML={{ __html: description }}
            ></div>
          </>
        )}
      </div>
    </div>
  );
}

export default Policies;

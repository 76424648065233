import React from "react";
import { Controller, useForm } from "react-hook-form";

import { NepaliDatePicker } from "nepali-datepicker-reactjs";

function StaffForm({ submit, buttonTitle, defaultValues }) {
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues: defaultValues });
  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className="form__text">
        <label htmlFor="name">Name</label>
        <input {...register("name", { required: true })} />
        <span className="form__error">
          {errors.name?.type === "required" && "Name is required"}
        </span>
      </div>
      <div className="form__text">
        <label htmlFor="designation">Designation</label>
        <input type="text" {...register("designation", { required: true })} />
        <span className="form__error">
          {errors.designation?.type === "required" && "Designation is required"}
        </span>
      </div>

      <div className="form__text">
        <label htmlFor="joined_date">Joined Date</label>
        <Controller
          id="joined_date"
          name="joined_date"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange } }) => (
            <NepaliDatePicker
              inputClassName="form__calendar"
              value={defaultValues?.joined_date}
              options={{ calenderLocale: "en", valueLocale: "en" }}
              onChange={(value) => onChange(value)}
            />
          )}
        />
        <span className="form__error">
          {errors.joined_date?.type === "required" && "Joined Date is required"}
        </span>
      </div>
      <div className="form__text">
        <label htmlFor="salary">Salary</label>
        <input type="number" {...register("salary", { required: true })} />
        <span className="form__error">
          {errors.salary?.type === "required" && "Salary is required"}
        </span>
      </div>
      <div className="form__text">
        <label htmlFor="phonenum">Phone</label>
        <input type="text" {...register("phonenum", { required: true })} />
        <span className="form__error">
          {errors.phonenum?.type === "required" && "Phone is required"}
        </span>
      </div>
      <div className="form__text">
        <label htmlFor="address">Address</label>
        <input type="text" {...register("address", { required: true })} />
        <span className="form__error">
          {errors.address?.type === "required" && "Address is required"}
        </span>
      </div>
      <button className="form__button--add">{buttonTitle}</button>
    </form>
  );
}

export default StaffForm;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../../helpers/axios";

export const updateElectricity = createAsyncThunk(
  "electricity/updateElectricity",
  async ({ startDate, useDays }, { rejectWithValue }) => {
    try {
      const response = await axios.post("/utilities/electricity", {
        start_date: startDate,
        use_days: useDays,
      });
      
      // console.log(response);

      const {
        data: { result },
      } = response;

      toast.success("Electricity Notifying Time Updated");

      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getElectricity = createAsyncThunk(
  "electricity/getElectricity",
  async (_, rejectWithValue) => {
    try {
      const response = await axios.get("/utilities/electricity");
      const {
        data: { result },
      } = response;

      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const electricitySlice = createSlice({
  name: "electricity",
  initialState: {
    notifyingDate: "",
    startDate: "",
    useDays: null,
    remainingDays: null,
    loading: false,
  },
  extraReducers: {
    [updateElectricity.pending]: (state) => {
      state.loading = true;
    },
    [updateElectricity.fulfilled]: (state, { payload }) => {
      state.notifyingDate = payload.notifying_date;
      state.startDate = payload.start_date;
      state.useDays = payload.use_days;
      state.remainingDays = payload.remaining_days;
      state.loading = false;
    },
    [getElectricity.pending]: (state) => {
      state.loading = true;
    },
    [getElectricity.fulfilled]: (state, { payload }) => {
      state.notifyingDate = payload.notifying_date;
      state.startDate = payload.start_date;
      state.useDays = payload.use_days;
      state.remainingDays = payload.remaining_days;
      state.loading = false;
    },
  },
});

export const selectElectricityRD = (state) => state.electricity.remainingDays;

export default electricitySlice.reducer;

export const EXPENSE_COLUMNS = [
  {
    Header: "Id",
    accessor: "id",
  },
  {
    Header: "Expense Name",
    accessor: "name",
  },
  {
    Header: "Amount",
    accessor: "amount",
  },
  {
    Header: "Description",
    accessor: "description",
  },
  {
    Header: "Date",
    accessor: "date",
  },
];

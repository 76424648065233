import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { getAvailableRooms } from "../../../redux/features/room/roomSlice";
import {
  getReservation,
  reservationCheckIn,
} from "../../../redux/features/reservation/reservationSlice";

import GuestForm from "../../guest/GuestForm";

import Loading from "../../../helpers/loading";

import "./styles.css";

function ReservationCheckIn() {
  const { id } = useParams();
  const history = useHistory();

  const dispatch = useDispatch();
  const { reservation, loading } = useSelector((state) => state.reservation);
  const { availableRooms } = useSelector((state) => state.room);

  useEffect(() => {
    dispatch(getAvailableRooms());
    dispatch(getReservation(id));
  }, [dispatch, id]);

  const add = (data) => {
    dispatch(reservationCheckIn({ data, rid: id }));

    history.push("/guest/list");
  };

  return (
    <div className="reservationCheckIn">
      <div className="reservationCheckIn__box">
        <h4 className="reservationCheckIn__title">Check In Reservation</h4>
        {loading ? (
          <Loading />
        ) : (
          <GuestForm
            buttonTitle="Check In"
            defaultValues={reservation}
            submit={add}
            availableRooms={availableRooms}
          />
        )}
      </div>
    </div>
  );
}

export default ReservationCheckIn;

import React from "react";
import { useHistory } from "react-router";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { addCreditRecord } from "../../../../redux/features/utilities/creditRecordsSlice";

import Loading from "../../../../helpers/loading";

import "../CreditRecord.css";
import CreditRecordForm from "../CreditRecordForm";

function CreditRecordAdd() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.creditRecords);

  const defaultValues = {
    uid: "",
    name: "",
    phone: "",
    address: "",
    amount: 0,
    paid: 0,
  };

  const onAdd = (data) => {
    dispatch(addCreditRecord(data));
    history.push("/utilities/credit");
  };

  return (
    <div className="creditRecordAdd">
      <div className="container__box">
        <h4>Add Credit Record</h4>
        {loading ? (
          <Loading />
        ) : (
          <CreditRecordForm
            defaultValues={defaultValues}
            submit={onAdd}
            buttonTitle="Add"
          />
        )}
      </div>
    </div>
  );
}

export default CreditRecordAdd;

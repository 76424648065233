import React from "react";

import { useDispatch } from "react-redux";
import { badGuest } from "../../../redux/features/guest/guestSlice";

function BadGuest({ id, isBadGuest }) {
  const dispatch = useDispatch();

  // Check Bad Guest
  const checkBadGuest = (id) => {
    dispatch(badGuest({ isBad: isBadGuest === 1 ? 0 : 1, id }));
  };

  return (
    <button
      className={`${
        isBadGuest === 1
          ? "guestDetails__badGuestBtn--bad"
          : "guestDetails__badGuestBtn--good "
      }`}
      onClick={() => checkBadGuest(id)}
    >
      {isBadGuest === 0 ? "Check BAD GUEST" : "Uncheck BAD GUEST"}
    </button>
  );
}

export default BadGuest;

import React, { useEffect } from "react";

import "./Water.css";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  updateWater,
  getWater,
} from "../../../redux/features/utilities/waterSlice";

import Notifying from "../../../components/notifying/Notifying";

import WaterLogoSvg from "../../../assets/images/water-logo.svg";

function Water() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getWater());
  }, [dispatch]);

  const { notifyingDate, startDate, useDays, loading } = useSelector(
    (state) => state.water
  );

  return (
    <div className="water">
      <Notifying
        logo={WaterLogoSvg}
        notifyingDate={notifyingDate}
        startDate={startDate}
        useDays={useDays}
        update={updateWater}
        loading={loading}
      />
    </div>
  );
}

export default Water;

import React, { PureComponent } from "react";
// import PrintPDF from "../../print";

import "./styles.css";

export class Bookings extends PureComponent {
  render() {
    const {
      id,
      rid,
      check_in,
      expected_stay_days,
      adult_num,
      child_num,
      rooms,
      booked_from,
      charge,
      remarks,
      discount,
      advance_payment,
      payments,
      payment_status,
      credit,
      total,
      food,
      services,
      check_status,
    } = this.props;
    return (
      <div key={rid}>
        {/* Guest Services */}
        <div className="guestDetails__item">
          <div className="guestDetails__rates">
            {/* Basic Services */}
            <div className="guestDetails__group">
              <h5 className="guestDetails__header">BOOKINGS DETAILS</h5>
            </div>
            <div className="guestDetails__group">
              <h5>Check In</h5>
              <span>{check_in}</span>
            </div>
            <div className="guestDetails__group">
              <h5>Stay Days</h5>
              <span>{expected_stay_days}</span>
            </div>
            <div className="guestDetails__group">
              <h5>Guests</h5>
              <h5>
                Adults: <span>{adult_num}</span>
              </h5>
              <h5>
                Children: <span>{child_num}</span>
              </h5>
            </div>
            <div className="guestDetails__group">
              <h5>Room No</h5>
              {rooms?.map((roomNo, index) => (
                <span key={index}>{roomNo.value}</span>
              ))}
            </div>
            <div className="guestDetails__group">
              <h5>Booked From</h5>
              <span>{booked_from}</span>
            </div>
            <div className="guestDetails__group">
              <h5>Remarks</h5>
              <span>{remarks}</span>
            </div>
            <div className="guestDetails__group">
              <h5>Charge</h5>
              <span>{charge}</span>
            </div>
            <div className="guestDetails__group">
              <h5>Discount</h5>
              <span>{discount}</span>
            </div>
            <div className="guestDetails__group">
              <h5>Advance</h5>
              <span>{advance_payment}</span>
            </div>

            <div className="guestDetails__group">
              <h5>Payment</h5>
              {payments?.length >= 1 ? (
                payments?.map((pay, index) => (
                  <div className="guestDetails__payment" key={index}>
                    <span>
                      {pay.amount}
                      <img
                        src={
                          require("../../../assets/icons/delete-icon.png")
                            .default
                        }
                        alt="delete payment"
                        className="guestDetails__paymentDelete"
                        onClick={() => this.props.paymentDelete(rid, pay.pid)}
                      />
                    </span>
                    <span className="guestDetails__paymentDate">
                      {pay.date}
                    </span>
                  </div>
                ))
              ) : (
                <h5>Not Yet</h5>
              )}
            </div>
            <div className="guestDetails__group">
              <h5>Payment Status</h5>
              <span>{payment_status}</span>
            </div>
            {/* Extra Services */}
            <div className="guestDetails__extras">
              <div className="guestDetails__food">
                <h4 className="guestDetails__header">FOOD</h4>
                <input
                  type="checkbox"
                  value={food}
                  checked={food === 1 ? true : false}
                  onChange={() => {
                    food === 1
                      ? this.props.onFoodUncheck({ id, rid })
                      : this.props.onFoodCheck({ id, rid });
                  }}
                />
              </div>
              <div className="guestDetails__services">
                <h4 className="guestDetails__header">SERVICES</h4>
                <input
                  type="checkbox"
                  value={services}
                  checked={services === 1 ? true : false}
                  onChange={() => {
                    services === 1
                      ? this.props.onServicesUncheck({ id, rid })
                      : this.props.onServicesCheck({ id, rid });
                  }}
                />
              </div>
              <div className="guestDetails__paid">
                <h5>PAID</h5>
                <span>{total}</span>
              </div>
              <div className="guestDetails__total">
                <h4>CREDIT</h4>
                <span>{credit}</span>
              </div>
            </div>
          </div>
          <div className="guestDetails__itemActions">
            <button
              className="g-edit__btn"
              onClick={() => this.props.gotoEdit({ id, rid })}
            >
              EDIT
            </button>
            <button
              className="g-delete__btn"
              onClick={() => this.props.onDelete({ rid, id })}
            >
              DELETE
            </button>
            {/* Add Payments */}
            {credit > 0 && (
              <button
                className="g-success__btn"
                onClick={() => this.props.toggleAddPaymentModal(rid)}
              >
                ADD PAYMENT
              </button>
            )}
            {/* Paid all credit at once */}
            {credit > 0 && (
              <button
                className="g-success__btn"
                onClick={() => this.props.payAll({ rid, id })}
              >
                MARK PAID
              </button>
            )}
          </div>
        </div>

        {/* Guest Payment */}
        <div className="guestDetails__final">
          {check_status === 0 ? (
            <button
              className={
                credit <= 0 && food === 1 && services === 1
                  ? "checkout--okay"
                  : "checkout--error"
              }
              onClick={() => {
                if (credit <= 0 && food === 1 && services === 1) {
                  this.props.onCheckout({ rid, id });
                }
              }}
            >
              Checkout
            </button>
          ) : (
            // <PrintPDF />
            <h4 className="guestDetails__checkedOut">CHECKED OUT</h4>
          )}
        </div>
      </div>
    );
  }
}

import React, { useState, useMemo, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";

import { NepaliDatePicker } from "nepali-datepicker-reactjs";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCost,
  getAllCosts,
} from "../../../../../redux/features/utilities/inventorySlice";

import { COST_COLUMNS } from "../CostColumn";
import Loading from "../../../../../helpers/loading";

import Filter from "../../../../../components/filter/Filter";
import Table from "../../../../../components/table/Table";
import { todayBS } from "../../../../../helpers/nepaliDate";

import "./styles.css";

function CostList() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { costs, loading } = useSelector((state) => state.inventory);

  const [fromDate, setFromDate] = useState(todayBS);
  const [toDate, setToDate] = useState(todayBS);
  const [q, setQ] = useState("");
  const [searchColumns, setSearchColumns] = useState(["expense"]);

  const columnsHeader = useMemo(() => COST_COLUMNS, []);
  const data = useMemo(() => costs, [costs]);

  useEffect(() => {
    dispatch(
      getAllCosts({
        from: fromDate,
        to: toDate,
      })
    );
  }, [dispatch, fromDate, toDate]);

  let columnsTitle = data[0] && Object.keys(data[0]);

  // Search Fields
  const filterColumns = [{ name: "Expense", value: "expense" }];

  function search(rows) {
    return rows.filter((row) =>
      searchColumns.some(
        (column) =>
          row[column]?.toString().toLowerCase().indexOf(q.toLowerCase()) > -1
      )
    );
  }

  const onEdit = (id) => {
    history.push(`/utilities/inventory/cost/edit/${id}`);
  };

  const onDelete = (id) => {
    dispatch(deleteCost(id));
    history.push("/utilities/inventory");
  };

  return (
    <div className="costList__box">
      <div className="costList__heading">
        <div className="costList__date">
          <div>
            <label>From : </label>
            <NepaliDatePicker
              className="g-datePicker"
              value={fromDate}
              options={{ calenderLocale: "en", valueLocale: "en" }}
              onChange={(value) => setFromDate(value)}
            />
          </div>
          <div>
            <label>To : </label>
            <NepaliDatePicker
              className="g-datePicker"
              value={toDate}
              options={{ calenderLocale: "en", valueLocale: "en" }}
              onChange={(value) => setToDate(value)}
            />
          </div>
        </div>
        <Link to="/utilities/inventory/cost/add" className="costList__actions">
          + New Expense
        </Link>
      </div>

      <Filter
        q={q}
        setQ={setQ}
        filterColumns={filterColumns}
        searchColumns={searchColumns}
        setSearchColumns={setSearchColumns}
      />

      <div className="costList__details">
        {loading ? (
          <Loading />
        ) : (
          <Table
            columns={columnsHeader}
            data={search(data)}
            columnsTitle={columnsTitle}
            link=""
            onEdit={onEdit}
            onDelete={onDelete}
          />
        )}
      </div>
    </div>
  );
}

export default CostList;

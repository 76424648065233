import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { addNewBooking } from "../../../redux/features/guest/guestSlice";
import { getAvailableRooms } from "../../../redux/features/room/roomSlice";

import Loading from "../../../helpers/loading";
import GuestForm from "../GuestForm";

function GuestExist() {
  const history = useHistory();
  const { guestID } = useParams();
  const dispatch = useDispatch();
  const { guestDetails, loading } = useSelector((state) => state.guest);
  const { availableRooms } = useSelector((state) => state.room);

  useEffect(() => {
    dispatch(getAvailableRooms());
  }, [dispatch]);

  const update = (data) => {
    dispatch(addNewBooking({ data, guestID }));

    history.goBack();
  };

  return (
    <div className="guestEdit">
      <div className="guestEdit__box">
        <h4 className="guestEdit__title">Add New Booking</h4>
        {loading ? (
          <Loading />
        ) : (
          <GuestForm
            buttonTitle="Add New Booking"
            defaultValues={guestDetails}
            submit={update}
            availableRooms={availableRooms}
          />
        )}
      </div>
    </div>
  );
}

export default GuestExist;

import React from "react";

import Functions from "../../components/functions/Functions";
import Header from "../../components/header/Header";

function Layouts({ children }) {
  return (
    <>
      <Header />
      <div className="app__content">{children}</div>
      <Functions />
    </>
  );
}

export default Layouts;

import React, { useEffect } from "react";
import "./Tv.css";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { updateTv, getTv } from "../../../redux/features/utilities/tvSlice";

import Notifying from "../../../components/notifying/Notifying";

import TvLogoSvg from "../../../assets/images/tv-logo.svg";

function Tv() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTv());
  }, [dispatch]);

  const { notifyingDate, startDate, useDays, loading } = useSelector(
    (state) => state.tv
  );
  return (
    <div className="tv">
      <Notifying
        logo={TvLogoSvg}
        notifyingDate={notifyingDate}
        startDate={startDate}
        useDays={useDays}
        update={updateTv}
        loading={loading}
      />
    </div>
  );
}

export default Tv;

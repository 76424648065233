import React from "react";

import "./Filter.css";

function Filter({ q, setQ, filterColumns, searchColumns, setSearchColumns }) {
  return (
    <div className="g-list__filters">
      <input type="text" value={q} onChange={(e) => setQ(e.target.value)} />
      {filterColumns &&
        filterColumns.map((column, index) => (
          <label key={index}>
            <input
              type="checkbox"
              checked={searchColumns.includes(column.value)}
              onChange={(e) => {
                const checked = searchColumns.includes(column.value);
                setSearchColumns((prev) =>
                  checked
                    ? prev.filter((sc) => sc !== column.value)
                    : [...prev, column.value]
                );
              }}
            />
            <span>{column.name}</span>
          </label>
        ))}
    </div>
  );
}

export default Filter;

import React from "react";
import ReactLoading from "react-loading";

function Loading() {
  return (
    <div className="loading">
      <ReactLoading
        type="spinningBubbles"
        color="#C19433"
        height={100}
        width={100}
      />
    </div>
  );
}

export default Loading;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../helpers/axios";
import { toast } from "react-toastify";

// Staff
export const getAllStaff = createAsyncThunk(
  "staffSalary/getAllStaff",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get("/staffsalary");

      return data.result.records;
    } catch (err) {
      toast.error("Staff Load Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

export const getStaffDetails = createAsyncThunk(
  "staffSalary/getStaffDetails",
  async (id, rejectWithValue) => {
    try {
      const { data } = await axios.get(`/staffsalary/view/${id}`);

      return data;
    } catch (err) {
      toast.error("Staff Load Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

export const addStaff = createAsyncThunk(
  "staffSalary/addStaff",
  async (
    { name, designation, phonenum, salary, address, joined_date },
    { rejectWithValue, dispatch }
  ) => {
    try {
      await axios.post("/staffsalary/store", {
        name,
        designation,
        phonenum,
        salary,
        address,
        joined_date,
      });

      dispatch(getAllStaff());

      toast.success("Staff Added!");
    } catch (err) {
      toast.error("Staff Add Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

export const updateStaff = createAsyncThunk(
  "staffSalary/updateStaff",
  async (
    { data: { name, designation, phonenum, salary, address, joined_date }, id },
    { rejectWithValue, dispatch }
  ) => {
    try {
      await axios.post(`/staffsalary/update/${id}`, {
        name,
        designation,
        phonenum,
        salary,
        address,
        joined_date,
      });

      dispatch(getAllStaff());

      toast.success("Staff Updated!");
    } catch (err) {
      toast.error("Staff Update Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteStaff = createAsyncThunk(
  "staffSalary/deleteStaff",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      await axios.post(`/staffsalary/delete/${id}`);

      dispatch(getAllStaff());

      toast.success("Staff Deleted!");
    } catch (err) {
      toast.error("Staff Delete Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

// Salary
export const getSalary = createAsyncThunk(
  "staffSalary/getSalary",
  async ({ id, year }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/staffsalary/getrecords/${id}/${year}`);

      const {
        data: { results },
      } = response;

      if (results === null) {
        return {
          baisakh: {
            paid_amount: 0,
            unpaid_amount: 0,
          },
          jestha: {
            paid_amount: 0,
            unpaid_amount: 0,
          },
          asaar: {
            paid_amount: 0,
            unpaid_amount: 0,
          },
          shraun: {
            paid_amount: 0,
            unpaid_amount: 0,
          },
          bhadra: {
            paid_amount: 0,
            unpaid_amount: 0,
          },
          ashwin: {
            paid_amount: 0,
            unpaid_amount: 0,
          },
          kartik: {
            paid_amount: 0,
            unpaid_amount: 0,
          },
          mangsir: {
            paid_amount: 0,
            unpaid_amount: 0,
          },
          poush: {
            paid_amount: 0,
            unpaid_amount: 0,
          },
          magh: {
            paid_amount: 0,
            unpaid_amount: 0,
          },
          falgun: {
            paid_amount: 0,
            unpaid_amount: 0,
          },
          chaitra: {
            paid_amount: 0,
            unpaid_amount: 0,
          },
        };
      }

      return results;
    } catch (err) {
      toast.error("Salary Load Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

export const setSalary = createAsyncThunk(
  "staffSalary/setSalary",
  async ({ year, months, id }, { rejectWithValue, dispatch }) => {
    try {
      // console.log(year);
      // console.log(months);
      // console.log(id);
      await axios.post(`/staffsalary/postrecords/${id}/${year}`, {
        months: months,
      });

      dispatch(getSalary({ id, year }));

      toast.success("Salary Saved!");
    } catch (err) {
      toast.error("Salary Save Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

export const staffSalarySlice = createSlice({
  name: "staffSalary",
  initialState: {
    year: "2078",
    rootMonths: {},
    staffs: [],
    staff: {},
    loading: false,
  },
  reducers: {
    changeSalaryYear: (state, { payload }) => {
      state.year = payload;
    },
  },
  extraReducers: {
    [getAllStaff.pending]: (state) => {
      state.loading = true;
    },
    [getAllStaff.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.staffs = payload;
    },
    [getAllStaff.rejected]: (state) => {
      state.loading = false;
    },
    [getStaffDetails.pending]: (state) => {
      state.loading = true;
    },
    [getStaffDetails.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.staff = payload;
    },
    [getStaffDetails.rejected]: (state) => {
      state.loading = false;
    },
    [addStaff.pending]: (state) => {
      state.loading = true;
    },
    [addStaff.rejected]: (state) => {
      state.loading = false;
    },
    [updateStaff.pending]: (state) => {
      state.loading = true;
    },
    [updateStaff.rejected]: (state) => {
      state.loading = false;
    },
    [deleteStaff.pending]: (state) => {
      state.loading = true;
    },
    [deleteStaff.rejected]: (state) => {
      state.loading = false;
    },
    [getSalary.pending]: (state) => {
      state.loading = true;
    },
    [getSalary.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.rootMonths = payload;
    },
    [getSalary.rejected]: (state) => {
      state.loading = false;
    },
    [setSalary.pending]: (state) => {
      state.loading = true;
    },
    [setSalary.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const { changeSalaryYear } = staffSalarySlice.actions;

export default staffSalarySlice.reducer;

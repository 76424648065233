import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../../helpers/axios";

import { todayBS } from "../../../helpers/nepaliDate";

// Advances
export const getAllAdvances = createAsyncThunk(
  "inventory/getAllAdvances",
  async ({ from = todayBS, to = todayBS }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/advances?from=${from}&&to=${to}`);

      return data;
    } catch (err) {
      toast.error("Particulars Load Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

export const getAdvanceById = createAsyncThunk(
  "inventory/getAdvanceById",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/advances/view/${id}`);

      return data;
    } catch (err) {
      toast.error("Particular Load Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

export const addAdvance = createAsyncThunk(
  "inventory/addAdvance",
  async ({ date, advance, amount }, { rejectWithValue, dispatch }) => {
    try {
      await axios.post("/advances/store", {
        date,
        advance,
        amount,
      });

      dispatch(getAllAdvances({ from: todayBS, to: todayBS }));

      toast.success("Particular Added!");
    } catch (err) {
      toast.error("Particular Add Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

export const updateAdvance = createAsyncThunk(
  "inventory/updateAdvance",
  async (
    { data: { date, advance, amount }, id },
    { rejectWithValue, dispatch }
  ) => {
    try {
      await axios.post(`/advances/update/${id}`, {
        date,
        advance,
        amount,
      });

      dispatch(getAllAdvances({ from: todayBS, to: todayBS }));

      toast.success("Particular Updated!");
    } catch (err) {
      toast.error("Particular Update Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteAdvance = createAsyncThunk(
  "inventory/deleteAdvance",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      await axios.post(`/advances/delete/${id}`);

      dispatch(getAllAdvances({ from: todayBS, to: todayBS }));

      toast.warn("Particular Deleted!");
    } catch (err) {
      toast.error("Particular Delete Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

// Costs
export const getAllCosts = createAsyncThunk(
  "inventory/getAllCosts",
  async ({ from = todayBS, to = todayBS }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/expenses?from=${from}&&to=${to}`);

      return data;
    } catch (err) {
      toast.error("Costs Load Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

export const getCostById = createAsyncThunk(
  "inventory/getCostById",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`expenses/view/${id}`);

      return data;
    } catch (err) {
      toast.error("Cost Load Failed");

      return rejectWithValue(err.response.data);
    }
  }
);

export const addCost = createAsyncThunk(
  "inventory/addCost",
  async ({ date, expense, amount }, { rejectWithValue, dispatch }) => {
    try {
      await axios.post("/expenses/store", {
        date,
        expense,
        amount,
      });

      dispatch(getAllCosts({ from: todayBS, to: todayBS }));

      toast.success("Cost Added!");
    } catch (err) {
      toast.error("Cost Add Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

export const updateCost = createAsyncThunk(
  "inventory/updateCost",
  async (
    { data: { date, expense, amount }, id },
    { rejectWithValue, dispatch }
  ) => {
    try {
      await axios.post(`/expenses/update/${id}`, {
        date,
        expense,
        amount,
      });

      dispatch(getAllCosts({ from: todayBS, to: todayBS }));

      toast.success("Cost Updated!");
    } catch (err) {
      toast.error("Cost Update Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteCost = createAsyncThunk(
  "inventory/deleteCost",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      await axios.post(`/expenses/delete/${id}`);

      dispatch(getAllCosts({ from: todayBS, to: todayBS }));

      toast.warn("Expense Deleted!");
    } catch (err) {
      toast.error("Expense Delete Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

// Inventory
export const getInventoryReport = createAsyncThunk(
  "inventory/getInventoryReport",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/totalpe?from=${todayBS}&&to=${todayBS}`);

      let report = {
        labels: ["Particulars", "Expenses"],
        datasets: [
          {
            label: "Inventory",
            data: [data?.result?.advances, data?.result?.expenses],
            backgroundColor: ["rgba(75, 192, 192, 1)", "rgba(255, 99, 132, 1)"],
            borderColor: ["rgba(75, 192, 192, 1)", "rgba(255, 99, 132, 1)"],
            borderWidth: 1,
          },
        ],
      };

      let isProfit = false;
      let profit = 0;
      let loss = 0;

      if (
        parseFloat(data?.result?.advances) > parseFloat(data?.result?.expenses)
      ) {
        isProfit = true;
        profit = data?.result?.advances - data?.result?.expenses;
      } else {
        isProfit = false;
        loss = data?.result?.expenses - data?.result?.advances;
      }

      return { report, isProfit, loss, profit };
    } catch (err) {
      toast.error("Inventory Load Failed!");

      // console.log(err.response.data);

      return rejectWithValue(err.response.data);
    }
  }
);

export const inventorySlice = createSlice({
  name: "inventory",
  initialState: {
    advances: [],
    advance: {},
    costs: [],
    cost: {},
    loading: false,
    inventoryReport: {},
    isProfit: false,
    profit: 0,
    loss: 0,
  },
  extraReducers: {
    [getAllAdvances.pending]: (state) => {
      state.loading = true;
    },
    [getAllAdvances.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.advances = payload.result.records;
    },
    [getAllAdvances.rejected]: (state) => {
      state.loading = false;
    },
    [getAdvanceById.pending]: (state) => {
      state.loading = true;
    },
    [getAdvanceById.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.advance = payload;
    },
    [getAdvanceById.rejected]: (state) => {
      state.loading = false;
    },
    [addAdvance.pending]: (state) => {
      state.loading = true;
    },
    [addAdvance.rejected]: (state) => {
      state.loading = false;
    },
    [updateAdvance.pending]: (state) => {
      state.loading = true;
    },
    [updateAdvance.rejected]: (state) => {
      state.loading = false;
    },
    [deleteAdvance.pending]: (state) => {
      state.loading = true;
    },
    [deleteAdvance.rejected]: (state) => {
      state.loading = false;
    },

    // Costs
    [getAllCosts.pending]: (state) => {
      state.loading = true;
    },
    [getAllCosts.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.costs = payload.result.records;
    },
    [getAllCosts.rejected]: (state) => {
      state.loading = false;
    },
    [getCostById.pending]: (state) => {
      state.loading = true;
    },
    [getCostById.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.cost = payload;
    },
    [getCostById.rejected]: (state) => {
      state.loading = false;
    },
    [addCost.pending]: (state) => {
      state.loading = true;
    },
    [addCost.rejected]: (state) => {
      state.loading = false;
    },
    [updateCost.pending]: (state) => {
      state.loading = true;
    },
    [updateCost.rejected]: (state) => {
      state.loading = false;
    },
    [deleteCost.pending]: (state) => {
      state.loading = true;
    },
    [deleteCost.rejected]: (state) => {
      state.loading = false;
    },

    // Inventory
    [getInventoryReport.pending]: (state) => {
      state.loading = true;
    },
    [getInventoryReport.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.inventoryReport = payload.report;
      state.isProfit = payload.isProfit;
      state.profit = payload.profit;
      state.loss = payload.loss;
    },
    [getInventoryReport.rejected]: (state) => {
      state.loading = false;
      state.inventoryReport = {};
      state.isProfit = false;
      state.profit = 0;
      state.loss = 0;
    },
  },
});

export default inventorySlice.reducer;

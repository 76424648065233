import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../../helpers/axios";

import { getAllGuests } from "../guest/guestSlice";

export const getAllReservations = createAsyncThunk(
  "reservation/getAllReservations",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get("/reservations");

      return data;
    } catch (err) {
      toast.error("Reservations Load Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

export const getReservation = createAsyncThunk(
  "reservation/getReservation",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/reservations/view/${id}`);

      return data;
    } catch (err) {
      toast.error("Reservation Load Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

export const addReservation = createAsyncThunk(
  "reservation/addReservation",
  async (
    {
      name,
      phone_number,
      address,
      adult_num,
      child_num,
      rooms,
      booked_from,
      check_in,
      expected_stay_days,
      charge,
      remarks,
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      await axios.post("/reservations/store", {
        name,
        phone_number,
        address,
        adult_num,
        child_num,
        rooms,
        booked_from,
        check_in,
        expected_stay_days,
        charge,
        remarks,
      });

      dispatch(getAllReservations());

      toast.success("Reservation Added!");
    } catch (err) {
      toast.error("Reservation Add Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

export const updateReservation = createAsyncThunk(
  "reservation/updateReservation",
  async (
    {
      data: {
        name,
        phone_number,
        address,
        adult_num,
        child_num,
        rooms,
        booked_from,
        check_in,
        expected_stay_days,
        charge,
        remarks,
      },
      id,
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      await axios.post(`/reservations/edit/${id}`, {
        name,
        phone_number,
        address,
        adult_num,
        child_num,
        rooms,
        booked_from,
        check_in,
        expected_stay_days,
        charge,
        remarks,
      });

      dispatch(getAllReservations());

      toast.success("Reservation Updated!");
    } catch (err) {
      toast.error("Reservation Update Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteReservation = createAsyncThunk(
  "reservation/deleteReservation",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      await axios.post(`reservations/delete/${id}`);

      dispatch(getAllReservations());

      toast.warn("Reservation Deleted!");
    } catch (err) {
      toast.error("Reservation Delete Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

export const getReservationsByRoom = createAsyncThunk(
  "reservation/getReservationsByRoom",
  async (roomid, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/rooms/reserved/${roomid}`);

      return data;
    } catch (err) {
      toast.error("Reservations By Room Load Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

export const reservationCheckIn = createAsyncThunk(
  "reservation/reservationCheckIn",
  async (
    {
      data: {
        name,
        phone_number,
        address,
        adult_num,
        child_num,
        booked_from,
        rooms,
        remarks,
        check_in,
        expected_stay_days,
        charge,
        discount,
        advance_payment,
        payment_status,
        guest_id,
      },
      rid,
    },
    { rejectWithValue, dispatch }
  ) => {
    const formdata = new FormData();
    formdata.append("name", name);
    formdata.append("phone_number", phone_number);
    formdata.append("address", address);
    formdata.append("adult_num", adult_num);
    formdata.append("child_num", child_num);
    formdata.append("booked_from", booked_from);
    formdata.append("rooms", JSON.stringify(rooms));
    formdata.append("remarks", remarks);
    formdata.append("check_in", check_in);
    formdata.append("expected_stay_days", expected_stay_days);
    formdata.append("charge", charge);
    formdata.append("discount", discount);
    formdata.append("advance_payment", advance_payment);
    formdata.append("payment_status", payment_status);
    formdata.append("guest_id", guest_id[0], guest_id[0].name);

    try {
      await axios.post(`/reservations/checkin/${rid}`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      dispatch(getAllGuests());

      toast.success("Guest Checked In!");
    } catch (err) {
      toast.error("Guest Check In Failed!");

      // console.log(err.response.data);

      return rejectWithValue(err.response.data);
    }
  }
);

const reservationSlice = createSlice({
  name: "reservation",
  initialState: {
    reservations: [],
    reservation: {},
    loading: false,
    reservationsByRoom: [],
  },
  extraReducers: {
    [getAllReservations.pending]: (state) => {
      state.loading = true;
    },
    [getAllReservations.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.reservations = payload.result.records;
    },
    [getAllReservations.rejected]: (state) => {
      state.loading = false;
    },
    [getReservation.pending]: (state) => {
      state.loading = true;
    },
    [getReservation.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.reservation = payload.records;
    },
    [getReservation.rejected]: (state) => {
      state.loading = false;
    },
    [addReservation.pending]: (state) => {
      state.loading = true;
    },
    [addReservation.rejected]: (state) => {
      state.loading = false;
    },
    [updateReservation.pending]: (state) => {
      state.loading = true;
    },
    [updateReservation.rejected]: (state) => {
      state.loading = false;
    },
    [deleteReservation.pending]: (state) => {
      state.loading = true;
    },
    [deleteReservation.rejected]: (state) => {
      state.loading = false;
    },
    [getReservationsByRoom.pending]: (state) => {
      state.loading = true;
    },
    [getReservationsByRoom.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.reservationsByRoom = payload;
    },
    [getReservationsByRoom.rejected]: (state) => {
      state.loading = false;
    },
    [reservationCheckIn.pending]: (state) => {
      state.loading = true;
    },
    [reservationCheckIn.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default reservationSlice.reducer;

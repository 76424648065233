import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../../helpers/axios";

export const updateWater = createAsyncThunk(
  "water/updateWater",
  async ({ startDate, useDays }, { rejectWithValue }) => {
    try {
      const response = await axios.post("/utilities/water", {
        start_date: startDate,
        use_days: useDays,
      });
      
      // console.log(response);

      const {
        data: { result },
      } = response;

      toast.success("Water Notifying Time Updated");

      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getWater = createAsyncThunk(
  "water/getWater",
  async (_, rejectWithValue) => {
    try {
      const response = await axios.get("/utilities/water");
      const {
        data: { result },
      } = response;

      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const waterSlice = createSlice({
  name: "water",
  initialState: {
    notifyingDate: "",
    startDate: "",
    useDays: null,
    remainingDays: null,
    loading: false,
  },
  extraReducers: {
    [updateWater.pending]: (state) => {
      state.loading = true;
    },
    [updateWater.fulfilled]: (state, { payload }) => {
      state.notifyingDate = payload.notifying_date;
      state.startDate = payload.start_date;
      state.useDays = payload.use_days;
      state.remainingDays = payload.remaining_days;
      state.loading = false;
    },
    [getWater.pending]: (state) => {
      state.loading = true;
    },
    [getWater.fulfilled]: (state, { payload }) => {
      state.notifyingDate = payload.notifying_date;
      state.startDate = payload.start_date;
      state.useDays = payload.use_days;
      state.remainingDays = payload.remaining_days;
      state.loading = false;
    },
  },
});

export const selectWaterRD = (state) => state.water.remainingDays;

export default waterSlice.reducer;

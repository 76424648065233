export const GUEST_COLUMNS = [
  {
    Header: "Id",
    accessor: "id",
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Phone",
    accessor: "phone_number",
  },
  {
    Header: "Address",
    accessor: "address",
  },
  {
    Header: "Bad Guest",
    accessor: "bad_guest",
  },
];

import React from "react";
import { useForm } from "react-hook-form";

function CreditRecordForm({ submit, buttonTitle, defaultValues }) {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues: defaultValues });
  return (
    <form onSubmit={handleSubmit(submit)}>
      <div className="form__text">
        <label htmlFor="name">Name</label>
        <input {...register("name", { required: true })} />
        <span className="form__error">
          {errors.name?.type === "required" && "Name is required"}
        </span>
      </div>
      <div className="form__text">
        <label htmlFor="phone">Phone</label>
        <input type="number" {...register("phone")} />
      </div>
      <div className="form__text">
        <label htmlFor="address">Address</label>
        <input {...register("address")} />
      </div>
      <div className="form__text">
        <label htmlFor="amount">Amount</label>
        <input type="number" {...register("amount", { required: true })} />
        <span className="form__error">
          {errors.name?.type === "required" && "Amount is required"}
        </span>
      </div>
      <div className="form__checkbox">
        <input type="checkbox" {...register("paid")} />
        <label htmlFor="paid">Paid</label>
      </div>
      <button className="form__button--add">{buttonTitle}</button>
    </form>
  );
}

export default CreditRecordForm;

import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import "./Rooms.css";

function Rooms() {
  const history = useHistory();

  const { rooms } = useSelector((state) => state.room);

  const gotoDetails = (is_available, guest_id) => {
    return is_available === 0
      ? history.push(`/guest/details/${guest_id}`)
      : null;
  };

  return (
    <div className="rooms">
      <div className="rooms__floor">
        {rooms.map(({ code, guest_id, rid, is_available, is_reserved, room }) => (
          <div className="rooms__box" key={code}>
            {guest_id !== null || rid !== null ? (
              <span className="rooms__guestID">
                {guest_id}
                <span className="rooms__bookID">{rid}</span>
              </span>
            ) : (
              <span>{}</span>
            )}
            <div
              className={`room rooms__room${code} ${
                is_available === 1 ? "room--free" : "room--occupied"
              }`}
            >
              {is_reserved ? (
                <div className="rooms__reserved">
                  <h4
                    onClick={() => {
                      history.push(`/reservation/reserved/${code}`);
                    }}
                  >
                    R
                  </h4>
                </div>
              ) : null}
              <h2 onClick={() => gotoDetails(is_available, guest_id)}>
                {room}
              </h2>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Rooms;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import axios from "../../../helpers/axios";

export const getCreditRecords = createAsyncThunk(
  "creditRecords/getCreditRecords",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get("/creditrecords");

      return data;
    } catch (err) {
      toast.error("Credit Records Load Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

export const getCreditRecord = createAsyncThunk(
  "creditRecords/getCreditRecord",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`creditrecords/view/${id}`);

      return data;
    } catch (err) {
      toast.error("Credit Record Load Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

export const addCreditRecord = createAsyncThunk(
  "creditRecords/addCreditRecord",
  async (
    { name, phone, address, amount, paid },
    { rejectWithValue, dispatch }
  ) => {
    try {
      await axios.post("/creditrecords/store", {
        name,
        phone,
        address,
        amount,
        paid,
      });

      dispatch(getCreditRecords());

      toast.success("Credit Record Added!");
    } catch (err) {
      toast.error("Credit Record Add Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

export const updateCreditRecord = createAsyncThunk(
  "creditRecords/updateCreditRecord",
  async (
    { data: { name, phone, address, amount, paid }, id },
    { rejectWithValue, dispatch }
  ) => {
    try {
      await axios.post(`creditrecords/update/${id}`, {
        name,
        phone,
        address,
        amount,
        paid,
      });

      dispatch(getCreditRecords());

      toast.success("Credit Record Updated!");
    } catch (err) {
      toast.error("Credit Record Update Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteCreditRecord = createAsyncThunk(
  "creditRecords/deleteCreditRecord",
  async (uid, { rejectWithValue, dispatch }) => {
    try {
      await axios.post(`/creditrecords/delete/${uid}`);

      dispatch(getCreditRecords());

      toast.warn("Credit Record Deleted!");
    } catch (err) {
      toast.error("Credit Record Delete Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

export const creditRecordsSlice = createSlice({
  name: "creditRecords",
  initialState: {
    records: [],
    loading: false,
    record: {},
  },
  extraReducers: {
    [getCreditRecords.pending]: (state) => {
      state.loading = true;
    },
    [getCreditRecords.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.records = payload.result.records;
    },
    [getCreditRecords.rejected]: (state) => {
      state.loading = false;
    },
    [getCreditRecord.pending]: (state) => {
      state.loading = true;
    },
    [getCreditRecord.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.record = payload;
    },
    [getCreditRecord.rejected]: (state) => {
      state.loading = false;
    },
    [addCreditRecord.pending]: (state) => {
      state.loading = true;
    },
    [addCreditRecord.rejected]: (state) => {
      state.loading = false;
    },
    [updateCreditRecord.pending]: (state) => {
      state.loading = true;
    },
    [updateCreditRecord.rejected]: (state) => {
      state.loading = false;
    },
    [deleteCreditRecord.pending]: (state) => {
      state.loading = true;
    },
    [deleteCreditRecord.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default creditRecordsSlice.reducer;

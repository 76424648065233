import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Line } from "react-chartjs-2";

import NepaliDate from "nepali-date-converter";
import { NepaliDatePicker } from "nepali-datepicker-reactjs";

import "./Monthly.css";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { getMonthlyReport } from "../../../../redux/features/salesAndExpenses/salesSlice";

import Loading from "../../../../helpers/loading";

const options = {
  scales: {
    y: {
      type: "linear",
      display: true,
      position: "left",
    },
  },
};

const todayAD = new Date();

const todayNepali = new NepaliDate(todayAD).getBS();

const fromBS = todayNepali.month === 0 ?  `${todayNepali.year - 1}-${todayNepali.month + 12}-${todayNepali.date}` : `${todayNepali.year}-${todayNepali.month}-${todayNepali.date}`;

const toBS = `${todayNepali.year}-${todayNepali.month + 1}-${todayNepali.date}`;

function MonthlySAE() {
  const [fromDate, setFromDate] = useState(fromBS);
  const [toDate, setToDate] = useState(toBS);
  const dispatch = useDispatch();
  const { monthlyReport, loading, isProfit, profit, loss } = useSelector(
    (state) => state.sales
  );

  useEffect(() => {
    dispatch(getMonthlyReport({ fromDate, toDate }));
  }, [dispatch, fromDate, toDate]);

  return (
    <div className="monthly">
      <div className="monthly__box">
        <h4>Monthly Sales And Expenses</h4>
        <div className="monthly__details">
          <Link
            to="/utilities/sae/monthly/sales"
            className="monthly__detailsBtn monthly__detailsBtn--sales"
          >
            Explore Monthly Sales
          </Link>
          <div className="monthly__date">
            <div className="monthly__startDate">
              <label htmlFor="startDate">From: </label>
              <NepaliDatePicker
                className="g-datePicker"
                value={fromDate}
                options={{ calenderLocale: "en", valueLocale: "en" }}
                onChange={(value) => setFromDate(value)}
              />
            </div>
            <div className="monthly__endDate">
              <label htmlFor="endDate">To: </label>
              <NepaliDatePicker
                className="g-datePicker"
                value={toDate}
                options={{ calenderLocale: "en", valueLocale: "en" }}
                onChange={(value) => setToDate(value)}
              />
            </div>
          </div>

          <Link
            to="/utilities/sae/monthly/expenses"
            className="monthly__detailsBtn monthly__detailsBtn--expense"
          >
            Explore Monthly Expenses
          </Link>
        </div>
        {loading ? (
          <Loading />
        ) : (
          <div className="monthly__chart">
            <div
              className={`daily__result ${
                isProfit ? "daily__result--profit" : "daily__result--loss"
              }`}
            >
              <h5>
                {isProfit ? "Profit" : "Loss"} : {isProfit ? profit : loss}
              </h5>
            </div>
            <Line data={monthlyReport} options={options} />
          </div>
        )}
      </div>
    </div>
  );
}

export default MonthlySAE;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import axios from "../../../helpers/axios";

export const getAllGuests = createAsyncThunk(
  "guest/getAllGuests",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get("/guests");

      return data;
    } catch (err) {
      toast.error("Guests Load Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

export const getGuestDetails = createAsyncThunk(
  "guest/getGuestDetails",
  async (guestID, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/guests/view/${guestID}`);

      return data;
    } catch (err) {
      toast.error("Guest Load Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteGuest = createAsyncThunk(
  "guest/deleteGuest",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      await axios.post(`/guests/delete/${id}`);

      dispatch(getAllGuests());

      toast.warn("Guest Deleted");
    } catch (err) {
      toast.error("Guest Delete Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

export const addGuest = createAsyncThunk(
  "guest/addGuest",
  async (
    {
      name,
      phone_number,
      address,
      adult_num,
      child_num,
      booked_from,
      rooms,
      remarks,
      check_in,
      expected_stay_days,
      charge,
      discount,
      advance_payment,
      payment_status,
      guest_id,
    },
    { rejectWithValue, dispatch }
  ) => {
    // console.log({
    //   name,
    //   phone_number,
    //   address,
    //   adult_num,
    //   child_num,
    //   booked_from,
    //   rooms,
    //   remarks,
    //   check_in,
    //   expected_stay_days,
    //   charge,
    //   discount,
    //   advance_payment,
    //   payment_status,
    //   guest_id,
    // });

    const formdata = new FormData();
    formdata.append("name", name);
    formdata.append("phone_number", phone_number);
    formdata.append("address", address);
    formdata.append("adult_num", adult_num);
    formdata.append("child_num", child_num);
    formdata.append("booked_from", booked_from);
    formdata.append("rooms", JSON.stringify(rooms));
    formdata.append("remarks", remarks);
    formdata.append("check_in", check_in);
    formdata.append("expected_stay_days", expected_stay_days);
    formdata.append("charge", charge);
    formdata.append("discount", discount);
    formdata.append("advance_payment", advance_payment);
    formdata.append("payment_status", payment_status);
    formdata.append("guest_id", guest_id[0], guest_id[0].name);

    // for (var pair of formdata.entries()) {
    //   console.log(pair[0] + "," + pair[1]);
    // }

    try {
      await axios.post("/guestrooms/store", formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      dispatch(getAllGuests());

      toast.success("Guest Added!");
    } catch (err) {
      toast.error("Guest Add Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

export const addNewBooking = createAsyncThunk(
  "guest/addNewBooking",
  async (
    {
      data: {
        name,
        phone_number,
        address,
        adult_num,
        child_num,
        booked_from,
        rooms,
        remarks,
        check_in,
        expected_stay_days,
        charge,
        discount,
        advance_payment,
        payment_status,
      },
      guestID,
    },
    { rejectWithValue, dispatch }
  ) => {
    const formdata = new FormData();
    formdata.append("name", name);
    formdata.append("phone_number", phone_number);
    formdata.append("address", address);
    formdata.append("adult_num", adult_num);
    formdata.append("child_num", child_num);
    formdata.append("booked_from", booked_from);
    formdata.append("rooms", JSON.stringify(rooms));
    formdata.append("remarks", remarks);
    formdata.append("check_in", check_in);
    formdata.append("expected_stay_days", expected_stay_days);
    formdata.append("charge", charge);
    formdata.append("discount", discount);
    formdata.append("advance_payment", advance_payment);
    formdata.append("payment_status", payment_status);

    try {
      await axios.post("/guestrooms/store", formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      dispatch(getGuestDetails(guestID));

      toast.success("Booking Added!");
    } catch (err) {
      toast.error("Booking Add Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

export const updateGuestBooking = createAsyncThunk(
  "guest/updateGuestBooking",
  async (
    {
      data: {
        name,
        phone_number,
        address,
        adult_num,
        child_num,
        booked_from,
        rooms,
        remarks,
        check_in,
        expected_stay_days,
        charge,
        discount,
        advance_payment,
        payment_status,
      },
      rid,
      guestID,
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      await axios.post(`/guestrooms/edit/${rid}`, {
        name,
        phone_number,
        address,
        adult_num,
        child_num,
        booked_from,
        rooms,
        remarks,
        check_in,
        expected_stay_days,
        charge,
        discount,
        advance_payment,
        payment_status,
      });

      dispatch(getGuestDetails(guestID));

      toast.success("Guest Updated!");
    } catch (err) {
      toast.error("Guest Update Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

export const getGuestBooking = createAsyncThunk(
  "guest/getGuestBooking",
  async (rid, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/guestrooms/view/${rid}`);

      return data;
    } catch (err) {
      toast.error("Guest Booking Load Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteGuestBooking = createAsyncThunk(
  "guest/deleteGuestBooking",
  async ({ rid, id }, { rejectWithValue, dispatch }) => {
    try {
      await axios.post(`/guestrooms/delete/${rid}`);

      dispatch(getGuestDetails(id));

      toast.success("Guest Booking Deleted!");
    } catch (err) {
      toast.error("Guest Booking Delete Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

export const checkoutGuestBooking = createAsyncThunk(
  "guest/checkoutGuestBooking",
  async ({ rid, id }, { rejectWithValue, dispatch }) => {
    try {
      await axios.post(`/guestrooms/checkout/${rid}`);

      dispatch(getGuestDetails(id));

      toast.success("Guest Checked Out!");
    } catch (err) {
      toast.error("Guest Booking checkout Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

export const foodCheck = createAsyncThunk(
  "guest/foodCheck",
  async ({ id, rid }, { rejectWithValue, dispatch }) => {
    try {
      await axios.post(`/guestrooms/mark/food/1/${rid}`);

      dispatch(getGuestDetails(id));

      toast.success("Food Checked!");
    } catch (err) {
      toast.error("Food Check Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

export const foodUncheck = createAsyncThunk(
  "guest/foodUncheck",
  async ({ id, rid }, { rejectWithValue, dispatch }) => {
    try {
      await axios.post(`/guestrooms/mark/food/0/${rid}`);

      dispatch(getGuestDetails(id));

      toast.success("Food Unchecked!");
    } catch (err) {
      toast.error("Food Uncheck Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

export const servicesCheck = createAsyncThunk(
  "guest/servicesCheck",
  async ({ id, rid }, { rejectWithValue, dispatch }) => {
    try {
      await axios.post(`/guestrooms/mark/services/1/${rid}`);

      dispatch(getGuestDetails(id));

      toast.success("Services Checked!");
    } catch (err) {
      toast.error("Services Check Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

export const servicesUncheck = createAsyncThunk(
  "guest/servicesUncheck",
  async ({ id, rid }, { rejectWithValue, dispatch }) => {
    try {
      await axios.post(`/guestrooms/mark/services/0/${rid}`);

      dispatch(getGuestDetails(id));

      toast.success("Services Unchecked!");
    } catch (err) {
      toast.error("Services Uncheck Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

export const markPaid = createAsyncThunk(
  "guest/markPaid",
  async ({ rid, id }, { rejectWithValue, dispatch }) => {
    try {
      await axios.post(`/guestrooms/markpaid/1/${rid}`);

      dispatch(getGuestDetails(id));

      toast.success("Booking Mark Paid!");
    } catch (err) {
      toast.error("Booking Marking Paid Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

export const badGuest = createAsyncThunk(
  "guest/badGuest",
  async ({ isBad, id }, { rejectWithValue, dispatch }) => {
    try {
      await axios.post(`/badguest/${isBad}/${id}`);

      dispatch(getGuestDetails(id));

      toast.success(isBad === 1 ? "Guest Checked Bad!" : "Guest Unchecked!");
    } catch (err) {
      toast.error("Guest Check Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

export const addPayment = createAsyncThunk(
  "guest/addPayment",
  async ({ guestID, bookID, amount }, { rejectWithValue, dispatch }) => {
    try {
      await axios.post(`/guestrooms/addpayment/${bookID}`, {
        amount,
      });

      dispatch(getGuestDetails(guestID));

      toast.success("Payment Added!");
    } catch (err) {
      toast.error("Payment Add Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

export const deletePayment = createAsyncThunk(
  "guest/deletePayment",
  async ({ guestID, rid, pid }, { rejectWithValue, dispatch }) => {
    try {
      await axios.post(`/guestrooms/deletepayment/${rid}/${pid}`);

      dispatch(getGuestDetails(guestID));

      toast.success("Payment Deleted!");
    } catch (err) {
      toast.error("Payment Delete Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

export const sendInfo = createAsyncThunk(
  "guest/sendInfo",
  async ({ id, content }, { rejectWithValue }) => {
    try {
      await axios.post(`/guests/smsinfo/${id}`, { content });

      toast.success("Info Sent!");
    } catch (err) {
      toast.error("Info Sending Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

export const sendBill = createAsyncThunk(
  "guest/sendBill",
  async ({ id, content }, { rejectWithValue }) => {
    try {
      await axios.post(`/guests/smsbill/${id}`, { content });

      toast.success("Bill Sent!");
    } catch (err) {
      toast.error("Bill Sending Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

export const getSMSInfo = createAsyncThunk(
  "guest/getSMSInfo",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get("/pages/get/smsinfo");

      return data.results.description;
    } catch (err) {
      toast.error("SMS Load Failed");

      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSMSInfo = createAsyncThunk(
  "guest/updateSMSInfo",
  async ({ content }, { rejectWithValue }) => {
    try {
      await axios.post("/pages/update/smsinfo", { description: content });

      toast.success("SMS Updated!");
    } catch (err) {
      toast.error("SMS Update Failed");

      return rejectWithValue(err.response.data);
    }
  }
);

export const getDeletedBookings = createAsyncThunk(
  "guest/getDeletedBookings",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get("/guestrooms?history=1");

      // console.log(data.result.records);

      return data.result.records;
    } catch (err) {
      toast.error("Deleted Bookings Load Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

export const guestSlice = createSlice({
  name: "guest",
  initialState: {
    guests: [],
    guestDetails: {},
    guestrooms: [],
    loading: false,
    guestBooking: {},
    smsInfo: "",
    deletedBookings: [],
  },
  reducers: {},
  extraReducers: {
    [getAllGuests.pending]: (state) => {
      state.loading = true;
    },
    [getAllGuests.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.guests = payload.result.records;
    },
    [getAllGuests.rejected]: (state) => {
      state.loading = false;
    },
    [getGuestDetails.pending]: (state) => {
      state.loading = true;
    },
    [getGuestDetails.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.guestDetails = payload.result.guest;
      state.guestrooms = payload.result.guestrooms;
    },
    [getGuestDetails.rejected]: (state) => {
      state.loading = false;
    },
    [addGuest.pending]: (state) => {
      state.loading = true;
    },
    [addGuest.rejected]: (state) => {
      state.loading = false;
    },
    [addNewBooking.pending]: (state) => {
      state.loading = true;
    },
    [addNewBooking.rejected]: (state) => {
      state.loading = false;
    },
    [deleteGuest.pending]: (state) => {
      state.loading = true;
    },
    [deleteGuest.rejected]: (state) => {
      state.loading = false;
    },
    [updateGuestBooking.pending]: (state) => {
      state.loading = true;
    },
    [updateGuestBooking.rejected]: (state) => {
      state.loading = false;
    },
    [getGuestBooking.pending]: (state) => {
      state.loading = true;
    },
    [getGuestBooking.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.guestBooking = payload.result.guest;
    },
    [getGuestBooking.rejected]: (state) => {
      state.loading = false;
    },
    [deleteGuestBooking.pending]: (state) => {
      state.loading = true;
    },
    [deleteGuestBooking.rejected]: (state) => {
      state.loading = false;
    },
    [checkoutGuestBooking.pending]: (state) => {
      state.loading = true;
    },
    [checkoutGuestBooking.rejected]: (state) => {
      state.loading = false;
    },
    [foodCheck.pending]: (state) => {
      state.loading = true;
    },
    [foodCheck.rejected]: (state) => {
      state.loading = false;
    },
    [foodUncheck.pending]: (state) => {
      state.loading = true;
    },
    [foodUncheck.rejected]: (state) => {
      state.loading = false;
    },
    [servicesCheck.pending]: (state) => {
      state.loading = true;
    },
    [servicesCheck.rejected]: (state) => {
      state.loading = false;
    },
    [servicesUncheck.pending]: (state) => {
      state.loading = true;
    },
    [servicesUncheck.rejected]: (state) => {
      state.loading = false;
    },
    [markPaid.pending]: (state) => {
      state.loading = true;
    },
    // [markPaid.fulfilled]: (state) => {
    //   state.loading = false;
    // },
    [markPaid.rejected]: (state) => {
      state.loading = false;
    },
    [badGuest.pending]: (state) => {
      state.loading = true;
    },
    // [badGuest.fulfilled]: (state) => {
    //   state.loading = false;
    // },
    [badGuest.rejected]: (state) => {
      state.loading = false;
    },
    [addPayment.pending]: (state) => {
      state.loading = true;
    },
    [addPayment.rejected]: (state) => {
      state.loading = false;
    },
    [deletePayment.pending]: (state) => {
      state.loading = true;
    },
    [deletePayment.rejected]: (state) => {
      state.loading = false;
    },
    [sendInfo.pending]: (state) => {
      state.loading = true;
    },
    [sendInfo.fulfilled]: (state) => {
      state.loading = false;
    },
    [sendInfo.rejected]: (state) => {
      state.loading = false;
    },
    [sendBill.pending]: (state) => {
      state.loading = true;
    },
    [sendBill.fulfilled]: (state) => {
      state.loading = false;
    },
    [sendBill.rejected]: (state) => {
      state.loading = false;
    },
    [getSMSInfo.pending]: (state) => {
      state.loading = true;
    },
    [getSMSInfo.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.smsInfo = payload;
    },
    [getSMSInfo.rejected]: (state) => {
      state.loading = false;
    },
    [updateSMSInfo.pending]: (state) => {
      state.loading = true;
    },
    [updateSMSInfo.fulfilled]: (state) => {
      state.loading = false;
    },
    [updateSMSInfo.rejected]: (state) => {
      state.loading = false;
    },
    [getDeletedBookings.pending]: (state) => {
      state.loading = true;
    },
    [getDeletedBookings.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.deletedBookings = payload;
    },
    [getDeletedBookings.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default guestSlice.reducer;

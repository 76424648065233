import React from "react";
import { Controller, useForm } from "react-hook-form";

import { NepaliDatePicker } from "nepali-datepicker-reactjs";

function CostForm({ submit, buttonTitle, defaultValues }) {
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues });
  return (
    <>
      <span className="form__error">
        {errors.date?.type === "required" && "Date is required"}
      </span>
      <span className="form__error">
        {errors.cost?.type === "required" && "Expense is required"}
      </span>
      <span className="form__error">
        {errors.amount?.type === "required" && "Amount is required"}
      </span>
      <form onSubmit={handleSubmit(submit)}>
        <div className="form__text">
          <label htmlFor="date">Expense Date</label>
          <Controller
            id="date"
            name="date"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange } }) => (
              <NepaliDatePicker
                inputClassName="form__calendar"
                value={defaultValues?.date}
                options={{ calenderLocale: "en", valueLocale: "en" }}
                onChange={(value) => onChange(value)}
              />
            )}
          />
        </div>
        <div className="form__text">
          <label htmlFor="expense">Expense</label>
          <input {...register("expense", { required: true })} />
        </div>
        <div className="form__text">
          <label htmlFor="amount">Amount</label>
          <input type="number" {...register("amount", { required: true })} />
        </div>
        <button className="form__button--add">{buttonTitle}</button>
      </form>
    </>
  );
}

export default CostForm;

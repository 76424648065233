import React from "react";
import { useHistory } from "react-router";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { addCapitalInvestment } from "../../../../redux/features/utilities/capitalInvestmentSlice";

import Loading from "../../../../helpers/loading";

import "../CapitalInvestment.css";
import CapitalInvestmentForm from "../CapitalInvestmentForm";

function CapitalInvestmentAdd() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.capitalInvestment);

  const defaultValues = {
    particulars: "",
    amount: 0,
    remarks: "",
  };

  const onAdd = (data) => {
    dispatch(addCapitalInvestment(data));
    history.push("/utilities/investment");
  };

  return (
    <div className="capitalInvestmentAdd">
      <div className="container__box">
        <h4>Add Capital Investment</h4>
        {loading ? (
          <Loading />
        ) : (
          <CapitalInvestmentForm
            submit={onAdd}
            buttonTitle="Add"
            defaultValues={defaultValues}
          />
        )}
      </div>
    </div>
  );
}

export default CapitalInvestmentAdd;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "../../../helpers/axios";

import { todayBS } from "../../../helpers/nepaliDate";
import { getDailyReport } from "./salesSlice";

export const getDailyExpenses = createAsyncThunk(
  "expenses/getDailyExpenses",
  async ({ from = todayBS, to = todayBS }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/dailyexpenses?from=${from}&&to=${to}`);

      return data;
    } catch (err) {
      toast.error("Expenses Load Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);

export const getExpense = createAsyncThunk(
  "expenses/getExpense",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/dailyexpenses/view/${id}`);

      return data;
    } catch (err) {
      toast.error("Sale Load Failed!");

      return rejectWithValue(err);
    }
  }
);

export const addExpenses = createAsyncThunk(
  "expenses/addExpenses",
  async (
    { name, amount, description, date },
    { rejectWithValue, dispatch }
  ) => {
    try {
      await axios.post("/dailyexpenses/store", {
        name,
        amount,
        description,
        date,
      });

      dispatch(getDailyExpenses({ from: todayBS, to: todayBS }));
      dispatch(getDailyReport());

      toast.success("Expense Added!");
    } catch (err) {
      toast.error("Expense Add Failed!");

      return rejectWithValue(err);
    }
  }
);

export const updateExpenses = createAsyncThunk(
  "expenses/updateExpenses",
  async (
    { data: { name, amount, description, date }, id },
    { rejectWithValue, dispatch }
  ) => {
    try {
      await axios.post(`/dailyexpenses/update/${id}`, {
        name,
        amount,
        description,
        date,
      });

      dispatch(getDailyExpenses({ from: todayBS, to: todayBS }));
      dispatch(getDailyReport());

      toast.success("Expense Updated!");
    } catch (err) {
      toast.error("Expense Update Failed!");

      return rejectWithValue(err);
    }
  }
);

export const deleteExpenses = createAsyncThunk(
  "expenses/deleteExpenses",
  async (uid, { rejectWithValue, dispatch }) => {
    try {
      await axios.post(`/dailyexpenses/delete/${uid}`);

      dispatch(getDailyExpenses({ from: todayBS, to: todayBS }));
      dispatch(getDailyReport());

      toast.warn("Expense Deleted!");
    } catch (err) {
      toast.error("Expense Delete Failed!");

      return rejectWithValue(err);
    }
  }
);

export const getDeletedExpenses = createAsyncThunk(
  "expenses/getDeletedExpenses",
  async ({ from = todayBS, to = todayBS }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/dailyexpenses?from=${from}&&to=${to}&&history=1`);

      return data;
    } catch (err) {
      toast.error("Delete Expenses Load Failed!");

      return rejectWithValue(err.response.data);
    }
  }
);


export const expensesSlice = createSlice({
  name: "expenses",
  initialState: {
    records: [],
    loading: false,
    expense: {},
  },
  extraReducers: {
    [getDailyExpenses.pending]: (state) => {
      state.loading = true;
    },
    [getDailyExpenses.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.records = payload.result.records;
    },
    [getDailyExpenses.rejected]: (state) => {
      state.loading = false;
    },
    [getExpense.pending]: (state) => {
      state.loading = true;
    },
    [getExpense.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.expense = payload;
    },
    [getExpense.rejected]: (state) => {
      state.loading = false;
    },
    [addExpenses.pending]: (state) => {
      state.loading = true;
    },
    [addExpenses.rejected]: (state) => {
      state.loading = false;
    },
    [updateExpenses.pending]: (state) => {
      state.loading = true;
    },
    [updateExpenses.rejected]: (state) => {
      state.loading = false;
    },
    [deleteExpenses.pending]: (state) => {
      state.loading = true;
    },
    [deleteExpenses.rejected]: (state) => {
      state.loading = false;
    },
    [getDeletedExpenses.pending]: (state) => {
      state.loading = true;
    },
    [getDeletedExpenses.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.records = payload.result.records;
    },
    [getDeletedExpenses.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default expensesSlice.reducer;

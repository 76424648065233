import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  checkoutGuestBooking,
  deleteGuestBooking,
  deletePayment,
  foodCheck,
  foodUncheck,
  servicesCheck,
  servicesUncheck,
  getGuestDetails,
  markPaid,
} from "../../../redux/features/guest/guestSlice";

import SMSBillModal from "../../../components/modals/SMS/SMSBillModal";
import SMSInfoModal from "../../../components/modals/SMS/SMSInfoModal";

import Loading from "../../../helpers/loading";

import "./styles.css";
import AddPaymentModal from "../../../components/modals/addPaymentModal";
import BadGuest from "../../../components/guest/BadGuest";
import { Bookings } from "../../../components/guest/Bookings";

const GuestDetails = () => {
  const { guestID } = useParams();
  const history = useHistory();

  const [smsInfoModal, setSmsInfoModal] = useState(false);
  const [smsBillModal, setSmsBillModal] = useState(false);
  const [addPaymentModal, setAddPaymentModal] = useState(false);

  // BookID for guest current booked item in booking array
  const [bookID, setBookID] = useState(null);

  const dispatch = useDispatch();
  const { guestDetails, guestrooms, loading } = useSelector(
    (state) => state.guest
  );

  useEffect(() => {
    dispatch(getGuestDetails(guestID));
  }, [dispatch, guestID]);

  // Clear all the credit of the user for that booking
  const payAll = ({ rid, id }) => {
    dispatch(markPaid({ rid, id }));
  };

  // toggle Bill Modal
  const toggleSmsBillModal = () => {
    setSmsBillModal((prevSmsBillModal) => !prevSmsBillModal);
  };

  // toggle Info Modal
  const toggleSmsInfoModal = () => {
    setSmsInfoModal((prevSmsInfoModal) => !prevSmsInfoModal);
  };

  // toggle payment modal
  const toggleAddPaymentModal = (rid) => {
    setBookID(rid);
    setAddPaymentModal((prevAddPaymentModal) => !prevAddPaymentModal);
  };

  const paymentDelete = (rid, pid) => {
    dispatch(deletePayment({ guestID, rid, pid }));
  };

  const onDelete = ({ rid, id }) => {
    dispatch(deleteGuestBooking({ rid, id }));
  };

  const onCheckout = ({ rid, id }) => {
    dispatch(checkoutGuestBooking({ rid, id }));
  };

  const onFoodCheck = ({ id, rid }) => {
    dispatch(foodCheck({ id, rid }));
  };

  const onFoodUncheck = ({ id, rid }) => {
    dispatch(foodUncheck({ id, rid }));
  };

  const onServicesCheck = ({ id, rid }) => {
    dispatch(servicesCheck({ id, rid }));
  };

  const onServicesUncheck = ({ id, rid }) => {
    dispatch(servicesUncheck({ id, rid }));
  };

  const gotoEdit = ({ id, rid }) => {
    history.push(`/guest/${id}/edit/${rid}`);
  };

  const { id, name, phone_number, address, bad_guest, guest_id } = guestDetails;

  return (
    <div className="guestDetails">
      {smsBillModal && (
        <SMSBillModal toggleModal={toggleSmsBillModal} id={id} />
      )}
      {smsInfoModal && (
        <SMSInfoModal toggleModal={toggleSmsInfoModal} id={id} />
      )}
      {addPaymentModal && (
        <AddPaymentModal
          toggleModal={toggleAddPaymentModal}
          bookID={bookID}
          guestID={id}
        />
      )}
      {loading ? (
        <Loading />
      ) : (
        <div
          className={`guestDetails__box ${
            bad_guest === 1 && "guestDetails--badGuest"
          }`}
        >
          <h4 className="guestDetails__title">
            GUEST DETAILS {bad_guest === 1 && " - Bad Guest"}
          </h4>
          <div className="guestDetails__content">
            <div className="guestDetails__info">
              {/* Guest Personal Info  */}
              <div className="guestDetails__personalInfo">
                <div className="guestDetails__user">
                  <div className="guestDetails__group">
                    <h5>Guest ID</h5>
                    <span>{id}</span>
                  </div>
                  <div className="guestDetails__group">
                    <h5>Name</h5>
                    <span>{name}</span>
                  </div>
                  <div className="guestDetails__group">
                    <h5>Address</h5>
                    <span>{address}</span>
                  </div>
                  <div className="guestDetails__group">
                    <h5>Phone No.</h5>
                    <span>{phone_number}</span>
                  </div>
                </div>
                <img
                  src={
                    guest_id
                      ? guest_id
                      : require("../../../assets/images/id-card.png").default
                  }
                  alt="guest identification"
                  className="guestDetails__identification"
                />
              </div>

              {/* Bookings List*/}
              {guestrooms.map(
                ({
                  rid,
                  check_in,
                  expected_stay_days,
                  adult_num,
                  child_num,
                  rooms,
                  booked_from,
                  charge,
                  remarks,
                  discount,
                  advance_payment,
                  payments,
                  payment_status,
                  credit,
                  total,
                  food,
                  services,
                  check_status,
                }) => (
                  <Bookings
                    key={rid}
                    id={id}
                    rid={rid}
                    check_in={check_in}
                    expected_stay_days={expected_stay_days}
                    adult_num={adult_num}
                    child_num={child_num}
                    rooms={rooms}
                    booked_from={booked_from}
                    charge={charge}
                    remarks={remarks}
                    discount={discount}
                    advance_payment={advance_payment}
                    payments={payments}
                    payment_status={payment_status}
                    credit={credit}
                    total={total}
                    food={food}
                    services={services}
                    check_status={check_status}
                    onCheckout={onCheckout}
                    payAll={payAll}
                    toggleAddPaymentModal={toggleAddPaymentModal}
                    onDelete={onDelete}
                    paymentDelete={paymentDelete}
                    onFoodUncheck={onFoodUncheck}
                    onFoodCheck={onFoodCheck}
                    onServicesCheck={onServicesCheck}
                    onServicesUncheck={onServicesUncheck}
                    gotoEdit={gotoEdit}
                  />
                )
              )}
            </div>

            <div className="guestDetails__actions">
              <button
                className="g-success__btn"
                onClick={() => history.push(`/guest/${id}/booking/new`)}
              >
                Add Booking
              </button>
              {/* Check guest as bad guest */}
              <BadGuest id={id} isBadGuest={bad_guest} />
              {/* Sms the guest */}
              <button className="g-edit__btn" onClick={toggleSmsInfoModal}>
                SMS INFO
              </button>
              <button className="g-edit__btn" onClick={toggleSmsBillModal}>
                SMS BILL
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GuestDetails;

import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  getCostById,
  updateCost,
} from "../../../../../redux/features/utilities/inventorySlice";

import CostForm from "../CostForm";
import Loading from "../../../../../helpers/loading";

import "./styles.css";

function CostEdit() {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { cost, loading } = useSelector((state) => state.inventory);

  useEffect(() => {
    dispatch(getCostById(id));
  }, [dispatch, id]);

  const onUpdate = (data) => {
    dispatch(updateCost({ data, id }));
    history.push("/utilities/inventory");
  };

  return (
    <div className="costEdit">
      <div className="container__box">
        <h4>Edit Inventory Expense</h4>
        {loading ? (
          <Loading />
        ) : (
          <CostForm
            submit={onUpdate}
            buttonTitle="Update"
            defaultValues={cost}
          />
        )}
      </div>
    </div>
  );
}

export default CostEdit;

import React, { useState, useMemo, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import { NepaliDatePicker } from "nepali-datepicker-reactjs";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  deleteExpenses,
  getDailyExpenses,
} from "../../../../../redux/features/salesAndExpenses/expensesSlice";

import Table from "../../../../../components/table/Table";
import Filter from "../../../../../components/filter/Filter";

import Loading from "../../../../../helpers/loading";

import { EXPENSE_COLUMNS } from "../../ExpenseColumn";

import "./styles.css";
import { todayBS } from "../../../../../helpers/nepaliDate";

function ExpenseDailyList() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { records, loading } = useSelector((state) => state.expenses);

  const [fromDate, setFromDate] = useState(todayBS);
  const [toDate, setToDate] = useState(todayBS);
  const [q, setQ] = useState("");
  const [searchColumns, setSearchColumns] = useState(["name"]);

  const columnsHeader = useMemo(() => EXPENSE_COLUMNS, []);
  const data = useMemo(() => records, [records]);

  useEffect(() => {
    dispatch(
      getDailyExpenses({
        from: fromDate,
        to: toDate,
      })
    );
  }, [dispatch, fromDate, toDate]);

  let columnsTitle = data[0] && Object.keys(data[0]);

  // Search Fields
  const filterColumns = [{ name: "Expense Name", value: "name" }];

  function search(rows) {
    return rows.filter((row) =>
      searchColumns.some(
        (column) =>
          row[column]?.toString().toLowerCase().indexOf(q.toLowerCase()) > -1
      )
    );
  }

  const onEdit = (id) => {
    history.push(`/utilities/sae/expenses/edit/${id}`);
  };

  const onDelete = (id) => {
    dispatch(deleteExpenses(id));
  };

  return (
    <div className="expense__box">
      <div className="expense__heading">
        <div className="expense__date">
          <div>
            <label>From : </label>
            <NepaliDatePicker
              className="g-datePicker"
              value={fromDate}
              options={{ calenderLocale: "en", valueLocale: "en" }}
              onChange={(value) => setFromDate(value)}
            />
          </div>
          <div>
            <label>To : </label>
            <NepaliDatePicker
              className="g-datePicker"
              value={toDate}
              options={{ calenderLocale: "en", valueLocale: "en" }}
              onChange={(value) => setToDate(value)}
            />
          </div>
        </div>
        <Link to="/utilities/sae/expenses/add" className="expense__actions">
          + New Expense
        </Link>
      </div>

      <Filter
        q={q}
        setQ={setQ}
        filterColumns={filterColumns}
        searchColumns={searchColumns}
        setSearchColumns={setSearchColumns}
      />

      <div className="expense__details">
        {loading ? (
          <Loading />
        ) : (
          <Table
            columns={columnsHeader}
            data={search(data)}
            columnsTitle={columnsTitle}
            link=""
            onEdit={onEdit}
            onDelete={onDelete}
          />
        )}
      </div>
    </div>
  );
}

export default ExpenseDailyList;

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import "./Utilities.css";

import ElectricityLogoSvg from "../../assets/images/electricity-logo.svg";
import TVLogoSvg from "../../assets/images/tv-logo.svg";
import TelephoneLogoSvg from "../../assets/images/telephone-logo.svg";
import WaterLogoSvg from "../../assets/images/water-logo.svg";

// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  getWater,
  selectWaterRD,
} from "../../redux/features/utilities/waterSlice";
import { getTv, selectTvRD } from "../../redux/features/utilities/tvSlice";
import {
  getElectricity,
  selectElectricityRD,
} from "../../redux/features/utilities/electricitySlice";
import {
  getTelephone,
  selectTelephoneRD,
} from "../../redux/features/utilities/telephoneSlice";

function Utilities() {
  const dispatch = useDispatch();
  const [utilities, setUtilities] = useState([]);
  const waterRD = useSelector(selectWaterRD);
  const tvRD = useSelector(selectTvRD);
  const electricityRD = useSelector(selectElectricityRD);
  const telephoneRD = useSelector(selectTelephoneRD);

  useEffect(() => {
    dispatch(getWater());
    dispatch(getElectricity());
    dispatch(getTv());
    dispatch(getTelephone());
    setUtilities([
      {
        id: "1",
        title: "Monthly Sales and Expenses",
        logo: null,
        daysLeft: null,
        link: "/utilities/sae/monthly",
      },
      {
        id: "2",
        title: "Inventory",
        logo: null,
        daysLeft: null,
        link: "/utilities/inventory",
      },
      {
        id: "3",
        title: "Electricity Service",
        logo: ElectricityLogoSvg,
        daysLeft: electricityRD,
        link: "/services/electricity",
      },
      {
        id: "4",
        title: "TV Service",
        logo: TVLogoSvg,
        daysLeft: tvRD,
        link: "/services/tv",
      },
      {
        id: "5",
        title: "Telephone Service",
        logo: TelephoneLogoSvg,
        daysLeft: telephoneRD,
        link: "/services/telephone",
      },
      {
        id: "6",
        title: "Water Service",
        logo: WaterLogoSvg,
        daysLeft: waterRD,
        link: "/services/water",
      },

      {
        id: "7",
        title: "Capital Investment",
        logo: null,
        daysLeft: null,
        link: "/utilities/investment",
      },
      {
        id: "8",
        title: "Credit Records",
        logo: null,
        daysLeft: null,
        link: "/utilities/credit",
      },
      {
        id: "9",
        title: "Staff Salary",
        logo: null,
        daysLeft: null,
        link: "/utilities/staff",
      },
      {
        id: "10",
        title: "Rent",
        logo: null,
        daysLeft: null,
        link: "/utilities/rent",
      },
      // {
      //   id: "11",
      //   title: "Hotel Important Information",
      //   logo: null,
      //   daysLeft: null,
      //   link: "/utilities/hotelInformation",
      // },
      {
        id: "12",
        title: "Hotel Policies / Info / Doc",
        logo: null,
        daysLeft: null,
        link: "/policies",
      },
      {
        id: "13",
        title: "WorldLink",
        logo: require("../../assets/images/worldlink-logo.png").default,
        daysLeft: null,
        link: "https://worldlink.com.np/",
      },
      {
        id: "14",
        title: "Esewa",
        logo: require("../../assets/images/esewa-logo.png").default,
        daysLeft: null,
        link: "https://esewa.com.np/",
      },
      {
        id: "15",
        title: "Google My Business",
        logo: require("../../assets/images/google-business-logo.png").default,
        daysLeft: null,
        link: "https://www.google.com/business/",
      },
      {
        id: "16",
        title: "Deleted Sales / Expenses",
        logo: null,
        daysLeft: null,
        link: "/sae/deleted",
      },
      {
        id: "17",
        title: "Deleted Bookings",
        logo: null,
        daysLeft: null,
        link: "/guest/deleted",
      },
    ]);
  }, [waterRD, electricityRD, telephoneRD, tvRD, dispatch]);

  const history = useHistory();
  const gotoPage = (link) => {
    history.push(link);
  };

  const openTab = (link) => {
    // open new link
    window.open(link, "_blank", "noopener,noreferrer");
  };
  return (
    <div className="utilities">
      {utilities.map(({ id, title, logo, daysLeft, link }) =>
        title === "Google My Business" ||
        title === "Esewa" ||
        title === "WorldLink" ? (
          <div
            className="utilities__item"
            key={id}
            onClick={() => openTab(link)}
          >
            {logo != null ? (
              <img src={logo} alt="logo" />
            ) : (
              <span>{title}</span>
            )}
            {daysLeft != null && (
              <div className="utilities__countDays">
                <span>{daysLeft}</span>
              </div>
            )}
          </div>
        ) : (
          <div
            className="utilities__item"
            key={id}
            onClick={() => gotoPage(link)}
          >
            {logo != null ? (
              <img src={logo} alt="logo" />
            ) : (
              <span>{title}</span>
            )}
            {daysLeft != null && (
              <div className="utilities__countDays">
                <span>{daysLeft}</span>
              </div>
            )}
          </div>
        )
      )}
    </div>
  );
}

export default Utilities;

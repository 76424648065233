import React, { useState, useMemo, useEffect } from "react";
import { Link } from "react-router-dom";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { getAllGuests } from "../../../redux/features/guest/guestSlice";

import Table from "../../../components/table/Table";
import { GUEST_COLUMNS } from "./GuestColumn";

import Filter from "../../../components/filter/Filter";
import Loading from "../../../helpers/loading";

import "./styles.css";

function GuestList() {
  const dispatch = useDispatch();
  const { guests, loading } = useSelector((state) => state.guest);

  const [q, setQ] = useState("");
  const [searchColumns, setSearchColumns] = useState(["name", "phone"]);

  const columnsHeader = useMemo(() => GUEST_COLUMNS, []);
  const data = useMemo(() => guests, [guests]);

  let columnsTitle = data[0] && Object.keys(data[0]);

  useEffect(() => {
    dispatch(getAllGuests());
  }, [dispatch]);

  // Remove room no and remarks field from table
  columnsTitle = columnsTitle?.filter(
    (columnTitle) =>
      columnTitle === "id" ||
      columnTitle === "name" ||
      columnTitle === "phone_number" ||
      columnTitle === "address" ||
      columnTitle === "bad_guest"
  );

  // Search Fields
  const filterColumns = [
    { name: "ID", value: "id" },
    { name: "Name", value: "name" },
    { name: "Phone", value: "phone_number" },
    { name: "Address", value: "address" },
    { name: "Bad Guest", value: "bad_guest" },
  ];

  function search(rows) {
    return rows.filter((row) =>
      searchColumns.some(
        (column) =>
          row[column]?.toString().toLowerCase().indexOf(q.toLowerCase()) > -1
      )
    );
  }

  // const del = (id) => {
  //   dispatch(deleteGuest(id));
  // };

  return (
    <div className="guestList container">
      <div className="g-list__box">
        <div className="g-list__heading">
          <h2 className="g-list__title">All Guests</h2>
          <Link to="/guest/add" className="g-list__actions">
            + New Guest
          </Link>
        </div>

        <Filter
          q={q}
          setQ={setQ}
          filterColumns={filterColumns}
          searchColumns={searchColumns}
          setSearchColumns={setSearchColumns}
        />

        <div className="g-list__details">
          {loading ? (
            <Loading />
          ) : (
            <Table
              columns={columnsHeader}
              data={search(data)}
              columnsTitle={columnsTitle}
              link="details"
              onEdit={null}
              onDelete={null}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default GuestList;

import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSales,
  getDailySales,
} from "../../../../../redux/features/salesAndExpenses/salesSlice";

import NepaliDate from "nepali-date-converter";
import { NepaliDatePicker } from "nepali-datepicker-reactjs";

import "./styles.css";

import Filter from "../../../../../components/filter/Filter";
import Table from "../../../../../components/table/Table";

import Loading from "../../../../../helpers/loading";

import { SALES_COLUMNS } from "../../../salesAndExpenses/SalesColumn";

const todayAD = new Date();

const todayNepali = new NepaliDate(todayAD).getBS();

const fromBS = todayNepali.month === 0 ?  `${todayNepali.year - 1}-${todayNepali.month + 12}-${todayNepali.date}` : `${todayNepali.year}-${todayNepali.month}-${todayNepali.date}`;

const toBS = `${todayNepali.year}-${todayNepali.month + 1}-${todayNepali.date}`;

function SalesMonthlyList() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { records, loading } = useSelector((state) => state.sales);

  const [fromDate, setFromDate] = useState(fromBS);
  const [toDate, setToDate] = useState(toBS);
  const [q, setQ] = useState("");
  const [searchColumns, setSearchColumns] = useState(["name", "amount"]);

  const columnsHeader = useMemo(() => SALES_COLUMNS, []);
  const data = useMemo(() => records, [records]);

  useEffect(() => {
    dispatch(
      getDailySales({
        from: fromDate,
        to: toDate,
      })
    );
  }, [dispatch, fromDate, toDate]);

  let columnsTitle = data[0] && Object.keys(data[0]);

  // Search Fields
  const filterColumns = [
    { name: "ID", value: "id" },
    { name: "Sales Name", value: "name" },
    { name: "Amount", value: "amount" },
    { name: "Description", value: "description" },
  ];

  function search(rows) {
    return rows.filter((row) =>
      searchColumns.some(
        (column) =>
          row[column]?.toString().toLowerCase().indexOf(q.toLowerCase()) > -1
      )
    );
  }

  const onEdit = (id) => {
    history.push(`/utilities/sae/sales/edit/${id}`);
  };

  const onDelete = (id) => {
    dispatch(deleteSales(id));
  };

  return (
    <div className="salesMonthlyList">
      <div className="salesMonthlyList__box">
        <div className="salesMonthlyList__heading">
          <h2 className="salesMonthlyList__title">Monthly Sales</h2>
          <div className="filter__date">
            <div className="filter__startDate">
              <label htmlFor="startDate">From</label>
              <NepaliDatePicker
                className="g-datePicker"
                value={fromDate}
                options={{ calenderLocale: "en", valueLocale: "en" }}
                onChange={(value) => setFromDate(value)}
              />
            </div>
            <div className="filter__endDate">
              <label htmlFor="endDate">To: </label>
              <NepaliDatePicker
                className="g-datePicker"
                value={toDate}
                options={{ calenderLocale: "en", valueLocale: "en" }}
                onChange={(value) => setToDate(value)}
              />
            </div>
          </div>
        </div>
        <Filter
          q={q}
          setQ={setQ}
          filterColumns={filterColumns}
          searchColumns={searchColumns}
          setSearchColumns={setSearchColumns}
        />

        <div className="g-list__details">
          {loading ? (
            <Loading />
          ) : (
            <Table
              columns={columnsHeader}
              data={search(data)}
              columnsTitle={columnsTitle}
              link=""
              onEdit={onEdit}
              onDelete={onDelete}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default SalesMonthlyList;

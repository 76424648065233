import React from "react";
import { Redirect, Route } from "react-router";

// redux
import { useSelector } from "react-redux";
import { selectAuth } from "../redux/features/app/appSlice";

import Layouts from "../pages/layouts/Layouts";

function PrivateRoute({ children, ...rest }) {
  const isAuthenticated = useSelector(selectAuth);
  
  return (
    <Route {...rest}>
      {isAuthenticated ? (
        <Layouts children={children} />
      ) : (
        <Redirect to="/login" />
      )}
    </Route>
  );
}

export default PrivateRoute;

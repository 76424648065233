import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { getReservationsByRoom } from "../../../redux/features/reservation/reservationSlice";

import Loading from "../../../helpers/loading";

import "./styles.css";

function FrontReservation() {
  const { roomid } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { reservationsByRoom, loading } = useSelector(
    (state) => state.reservation
  );

  useEffect(() => {
    dispatch(getReservationsByRoom(roomid));
  }, [dispatch, roomid]);

  return (
    <div className="frontReservation">
      <div className="frontReservation__box">
        {loading ? (
          <Loading />
        ) : (
          reservationsByRoom.map(
            ({
              rid,
              name,
              phone_number,
              address,
              adult_num,
              child_num,
              rooms,
              booked_from,
              check_in,
              expected_stay_days,
              charge,
              remarks,
              entry_date,
            }) => (
              <div key={rid}>
                <div className="frontReservation__header">
                  <h4 className="frontReservation__title">
                    {rid} Reservation Details
                  </h4>
                  <div className="frontReservation__actions">
                    <button
                      className="g-edit__btn"
                      onClick={() => history.push(`/reservation/edit/${rid}`)}
                    >
                      Edit
                    </button>
                    <button
                      className="g-delete__btn"
                      onClick={() => history.push("/reservation/list")}
                    >
                      Cancel
                    </button>
                    <button
                      className="g-success__btn"
                      onClick={() =>
                        history.push(`/reservation/checkin/${rid}`)
                      }
                    >
                      Check In
                    </button>
                  </div>
                </div>
                <div className="frontReservation__content">
                  <div className="frontReservation__group">
                    <h5>ID</h5>
                    <span>{rid}</span>
                  </div>
                  <div className="frontReservation__group">
                    <h5>Name</h5>
                    <span>{name}</span>
                  </div>
                  <div className="frontReservation__group">
                    <h5>Phone</h5>
                    <span>{phone_number}</span>
                  </div>
                  <div className="frontReservation__group">
                    <h5>Address</h5>
                    <span>{address}</span>
                  </div>
                  <div className="frontReservation__group">
                    <h5>Guests</h5>
                    <h5>
                      Adults: <span>{adult_num}</span>
                    </h5>
                    <h5>
                      Children: <span>{child_num}</span>
                    </h5>
                  </div>
                  <div className="frontReservation__group">
                    <h5>Room</h5>
                    {rooms?.map((roomNo, index) => (
                      <span key={index}>{roomNo}</span>
                    ))}
                  </div>
                  <div className="frontReservation__group">
                    <h5>Booked From</h5>
                    <span>{booked_from}</span>
                  </div>
                  <div className="frontReservation__group">
                    <h5>Check In</h5>
                    <span>{check_in}</span>
                  </div>
                  <div className="frontReservation__group">
                    <h5>Stay Days</h5>
                    <span>{expected_stay_days}</span>
                  </div>

                  <div className="frontReservation__group">
                    <h5>Charge</h5>
                    <span>{charge}</span>
                  </div>
                  <div className="frontReservation__group">
                    <h5>Remarks</h5>
                    <span>{remarks}</span>
                  </div>
                  <div className="frontReservation__group">
                    <h5>Reservation Time</h5>
                    <span>{entry_date}</span>
                  </div>
                </div>
                <hr style={{ margin: "1rem 0" }} />
              </div>
            )
          )
        )}
      </div>
    </div>
  );
}

export default FrontReservation;
